.customPageContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .spinContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .errorContainer {
    text-align: center;
    padding: 2rem;
  }
  .contentContainer {
    line-height: 1.6;
    font-size: 16px;
  }

  .pageTitle {
    text-transform: capitalize;
  }
  
  /* Styles for the HTML content */
  .customPageContainer :global(h1),
  .customPageContainer :global(h2),
  .customPageContainer :global(h3),
  .customPageContainer :global(h4),
  .customPageContainer :global(h5),
  .customPageContainer :global(h6) {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .customPageContainer :global(p) {
    margin-bottom: 1em;
  }
  
  .customPageContainer :global(ul),
  .customPageContainer :global(ol) {
    margin-bottom: 1em;
    padding-left: 2em;
  }
  
  .customPageContainer :global(img) {
    max-width: 100%;
    height: auto;
    margin: 1em 0;
  }
  
  .customPageContainer :global(blockquote) {
    border-left: 4px solid #ccc;
    margin: 1em 0;
    padding-left: 1em;
    font-style: italic;
  }
  
  .customPageContainer :global(pre),
  .customPageContainer :global(code) {
    background-color: #f4f4f4;
    border-radius: 3px;
    padding: 0.2em 0.4em;
    font-family: monospace;
  }
  
  .customPageContainer :global(table) {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .customPageContainer :global(th),
  .customPageContainer :global(td) {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .customPageContainer :global(th) {
    background-color: #f2f2f2;
    font-weight: bold;
  }