.lostCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6em 0;
}

.lostHeader {
    font-size: 4.8rem !important;
    font-weight: 800 !important;
}

