/* 
  CART COUNT BTN
*/

.cartCountCon {
  display: flex;
}

button.cartCountBtn {
  border: 1px solid #343433;
}

.cartCountSmall .cartCountIcon {
  font-size: 1.4rem !important;
}

.cartCountSmall button.cartCountBtn {
  padding: 1em !important;
}

.cartCountInput{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 0.2em !important;
  margin-right: 0.2em !important;
  width: 6.6em !important;
  min-height: 100% !important;
  border: 1px solid #343433 !important;
  height: 3em !important;
  
}

.cartCountText {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem !important;
  font-weight: 600;
  border: 1px solid #343433;
  width: 6.6em;
  min-height: 100%;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.cartCountTextHidden {
  padding: 1em;
}

.cartCountSmall .cartCountText {
  width: 4.6em;
}

/* 
  LINK BTN
*/

.linkBtn {
  display: inline-block;
}

.linkBtn:hover, .linkBtn:hover *  {
  /* color: var(--color-text-in-secondary) !important */
}

.linkBtn button {
  width: 100%;
}

/* 
  PRIMARY
*/

button.primaryBtn {
  height: 4em;
  padding-left: 3em;
  padding-right: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0em;
}

button.primaryBtnIconOnly {
  height: auto;
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (min-width: 62em) {
  button.primaryBtn  {
    height: 3em;
  }
}

button.primaryBtn * {
  font-weight: 400;
  font-size: 1.4rem
}

.primaryBtnIconOnly * {
  font-size: 2.2rem !important;
}

.primaryBtnIconRight {
  padding-left: 0.5em;
  display: flex !important;
  justify-content: center;
  align-items: center;
}


/*
  SECONDARY BTN
*/

button.secondaryBtn {
  border-radius: 8px !important;
}

/* 
  SHOP MORE
*/

.carouselOptionsContainer {
  position: relative; 
  display: flex;
  padding-left: 1em;
}

@media (min-width: 43em) {
  .carouselOptionsContainer {
    position: absolute; 
    bottom: 0; 
    right: 0;
  }
}

.carouselCategory {
  width: 8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
  padding-right: 1em;
  background-color: var(--color-background);
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  border: 1px solid var(--color-separator-light);
  cursor: pointer;
}

@media (min-width: 62em) {
  .carouselCategory {
    font-size: 1.2rem;
    width: 10em;
  }
}

.carouselCategoryIcon {
  font-size: 3.0rem;
  margin-bottom: 0.1em !important;
}

.carouselCtaBtnLink {
  flex: 1;
}

.carouselCtaBtn {
  flex: 1;
  height: 5em !important;
  padding: 1em !important;
}

button.carouselCtaBtn *  {
  font-size: 0.9rem !important;
}

@media (min-width: 62em) {
  .carouselCtaBtn {
    width: 12em;
    height: 8em !important;
  }

  button.carouselCtaBtn *  {
    font-size: 1.2rem !important;
  }
}

.carouselCtaBtn * {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.carouselMoreIcon {
  font-size: 1.6rem !important;
}

@media (min-width: 62em) {
  .carouselCategoryIcon {
    font-size: 4.0rem;
  }

  .carouselMoreIcon {
    font-size: 1.9rem !important;
  }
}

/* apple sign in button styling */
.signinButton {
  width: 210px !important;
  height: 40px;
  background-color: black;
  border: 1px solid #ccc;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.inputWithError {
  display: flex;
  flex-direction: column;
  height: 3em !important;
}

.errorText{
  color:red;
  font-size: 1.2rem !important;
  display: inline-block;
  white-space: nowrap !important;
  margin-top: 2em;
  width: 6.6em;
  z-index: 1;
}
