.circularButton {
  width: 30px;
  height: 25px;
  border-radius: 15px; 
  text-align: center;
  padding: 2px;
}
.inputDisabled {
  color: #ccc !important;
}

.datePicker {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  width: 100% !important;
  font-size: 1.2rem !important;
}