
/*
  CTA
*/

.carouselItem {
  height: 90vh;
}

.ctaSubText {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .ctaSubText {
    font-size: 1.6rem !important;
  }
}

/*
  FILTER
*/

.filtersCon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .filtersCon {
    padding: 4em 7em;
  }
}

.filtersHeader {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .filtersHeader {
    font-size: 2.8rem !important;
  }
}

.filtersOptCon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filterSearchIcon {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .filterSearchIcon {
    display: none !important;
  }
}

.filterSearchInputCon {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .filterSearchInputCon {
    display: flex;
    align-items: flex-end;
  }
}

.filterSearchInput {
  height: 100% !important;
}

.filterSelect {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .filterSelect {
    width: 16em !important
  }
}

/*
  SPECIALS LIST
*/

.specialsListCon {
  display: block;
  padding: 1em;
}

@media (min-width: 43em) {
  .specialsListCon {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 62em) {
  .specialsListCon {
    padding: 1em 7em;
  }
}
