.checkoutSidePanelContent {
  background-color: var(--color-background);
  padding: 2em;
  /* height: 40em; */
  overflow-y: scroll;
}

.sideTotalItems {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

.sideCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backOrderTextCon {
  display: block !important;
}

.backOrderText {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem !important;
  color: red !important;
  width: 100% !important;
}

.checkoutSidePanelContentText .sideCon {
  flex-direction: row;
  padding: 1em 0em;
}

.sideConIndex {
  display: none !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  padding-right: 1em;
}

.checkoutSidePanelContentText .sideConIndex {
  display: block !important;
}

.sideConText {
  width: calc(60% - 1em)
}

.checkoutSidePanelContentText .sideConText {
  flex: 1;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sideConTextName {
  font-size: 1.6rem !important;
  display: block !important;
  margin-bottom: 1em;
  padding-right: 1em;
}

.checkoutSidePanelContentText .sideConTextName {
  margin-bottom: 0em;
  flex: 1;
}

.sideConTextPriceCon {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sideConTextPrice {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.sideConTextPriceOut {
  font-size: 1.2rem !important;
  text-decoration: line-through;
}

.sideConTextQty {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  margin-left: 1em;
}

.cartItemImgCon {
  height: 10em;
  width: 40%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.checkoutSidePanelContentText .cartItemImgCon {
  display: none !important;
}

.cartItemImg {
  width: auto !important;
  height: auto !important;
  max-width: 60%;
  max-height: 60%;
}


/*
  DISCOUNT CLAIM
*/

.claimDiscountHeader {
  padding: 1em 0em 1em 0em;
  cursor: pointer;
}


/*
  DELIVERY PRICING
*/

.deliveryHeader {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 1em 0em;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.deliveryOptsCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.deliveryOptsCon * {
  font-size: 1.5rem !important;
}

.deliveryOptsConActive * {
  font-weight: 500 !important;
}

.deliveryOpts {
  flex: 1 !important;
  display: flex;
  align-items: center;
}

.deliveryOptsIcon {
  font-size: 1.1rem;
  margin: auto 0.5em;
  color: var(--color-background)
}

.deliveryOptsConActive .deliveryOptsIcon  {
  color: inherit;
}


/*
 * VAT PRICING
 */
.vatPriceCon {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 0em 0em ;
}

/*
  TOTAL PRICING
*/

.totalPriceCon {
  display: flex;
  justify-content: space-between;
  padding: 2em 0em 1em 0em ;
  border-top: 1px var(--color-title-in-primary) solid;
}

.totalPriceHeader {
  display: block;
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

.totalPriceSubHeader {
  display: block;
  font-size: 1.4rem !important;
  color: #9A9A9A !important;
}

.totalPrice {
  font-size: 2.6rem !important;
  font-weight: 700 !important;
  padding-left: 1em;
}

/*
  EMPTY LIST
 */

.emptyListCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}
