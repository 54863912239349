/* .cartCon {
} */

.deletedItem {
  color: red !important;
  font-size: 14px !important; /* Adjust size to your preference */
}
.favoriteProduct {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
}
.favoriteProduct:hover {
  background: lightgray;
}

.cartTableViewContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.cartTableView {
  display: none;
}

@media (min-width: 62em) {
  .cartTableView {
    display: inherit;
  }
}

.cartTableRow {
  height: 50px !important;
}

.cartTableProductInfo {
  padding: 1em;
}

.cartMobView {
  display: block;
}

@media (min-width: 62em) {
  .cartMobView {
    display: none;
  }
}

.cartItemCon {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  padding: 2em;
  margin-bottom: 1em;
}

.cartItemCloseIconCon {
  align-self: flex-end;
}

.cartItemCloseIconCon button {
  padding: 0em !important;
}

@media (min-width: 62em) {
  .cartItemCloseIconCon {
    padding: inherit !important;
    display: block !important;
  }
}

.cartItemCloseIcon {
  font-size: 1.8em;
  color: var(--color-primary);
}

.cartItemName {
  font-size: 1.8rem !important;
  font-weight: 600;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

@media (min-width: 62em) {
  .cartItemName {
    font-size: 2.0rem !important;
    line-height: 1.5em;
  }
}

.cartItemDetailsCon {
  height: 14em;
  display: flex;
  margin-bottom: 1em;
}

.cartItemImgCon {
  height: 100%;
  width: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media (min-width: 62em) {
  .cartItemImgCon {
    margin: auto;
    width: 80%;
    height: 120px;
  }
}

.cartItemImg {
  width: auto !important;
  height: auto !important;
  max-width: 90%;
  max-height: 90%;
}

@media (min-width: 62em) {
  .cartItemImg {
    max-width: 70%;
    max-height: 70%;
  }
}

.cartItemDetails {
  width: 50%;
}

.cartItemDetailsSku {
  display:block;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .cartItemDetailsSku {
    font-weight: 400 !important;

  }
}

.cartItemDetailsFieldName {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.cartItemDetailsFieldValue {
  font-size: 1.6rem !important;
}

.cartItemPriceCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cartItemPrice {
  font-size: 2.0rem !important;
  font-weight: bold !important;
}


/*
  TABLE EMPTY INDICATOR
*/

.emptyListCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}

.emptyListShop {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}


/*
 * PRICING INFO
 */
.pricingInfo{
  display: flex;
  flex-direction: column;
  height: auto !important
}


.backOrderText {
  font-size: 1.4rem !important;
  color: red !important;
  margin-bottom: 2em;
}

.priceConSelectedTbl{
  height: auto;
}

