
.avatar-default-user-icon {
  font-size: 1em;
}

.account-avatar-container {
  background: none !important; 
  border: 2px solid var(--color-text-in-primary) !important;
}


nav.transparent-navbar .account-avatar-container {
  border: 2px solid var(--color-title-nav-transparent) !important;
}
