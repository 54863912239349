.review-drawer-root{
    display: flex;
    flex-direction: column;
}

.selected-card{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: #1D3948 2px solid;
    color: #fff !important;
    background: #1E7D79 !important;
}

.selected-rev-card-name{
    color: #f75d03 !important;
}
.selected-rev-card-role {
    color: #fff !important;
}

.review-card{
    display: flex;
    flex-direction: column;
    // height: 130px;
    // width: 300px;
    border-radius: 0px;
    background: #F8FAFB;
    padding: 15px;
    margin: 0 10px 0 0px;
    cursor: pointer;
}

.rev-card-top{
    display: flex;
    justify-content: flex-end;
}
.rev-card-bottom{
    display: flex;
    justify-content: flex-end;
}

.rev-card-row{
    display: flex;
margin: auto;
justify-content: flex-start;
width: 90%;
height: 191px;
position: fixed;
top: 0;
z-index: 10;
}

.rev-card-tag{
    background-color: var(--color-primary);
    padding: 5px;
    color: #fff;
    font-size: 1rem;
    margin: 6px 0 0 0;
    min-width: 70px;
    text-align: center;
}

.rev-card-role{
    font-size: 1.1rem;
    color: var(--color-primary);
}
.rev-card-name{
    color: var(--color-primary);
    font-weight: bold;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rev-text-col{
    width: 70%;
}

.header{
    font-size: 1.6rem;
    color: #B14000 !important;
    font-weight: bold;
}

.header-res{
    font-size: 1.6rem;
    color: #B14000 !important;
    font-weight: bold;
    margin-top:14px
}

.rev-card-profile{
    display: flex;
    align-items: center;
}

.rev-card-avatar{
    width: 50px !important;
    height: 50px !important;
    margin: 0 12px 0 0 !important;
}

.rev-accordion{
    width: 85%;
    margin:  50px auto !important;
}

.next-survey-btn{
    background-color:  var(--color-primary) !important;
    margin: 17px auto !important;
    color: #fff !important;
    width: 120px !important;
    border: none !important;
}

.rev-accordion .ant-collapse-header{
    background-color: #7C8DA9 !important;
    color: #fff !important;
    font-size: 1.5rem !important;
    font-weight: bold;
}

.survey-response-area{
    margin: 60px auto;
    width: 84% !important;
}

.completed-check{
    font-size: 2rem !important;
    color: #304D12 !important;
}

.completed-text{
    color: #304D12 !important;
}

.non-complete{
    font-size: 1.8rem !important;
    font-weight: 700 !important;
    color: #1D3948 !important;
    font-weight: bolder;
}

.completed-rev{
    opacity: 0.7;
    cursor: not-allowed;
}

.survey-completed-view{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.no-del-view{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top:15%;
}

.completed-img{
    margin: 30px;
    width: 50%;
}
.no-del-img{
    margin: 30px;
    width: 20%;
}

.submit-rev-btn{
    background-color: #1E7D79 !important;
    color: #fff !important;
}

.thanks-text{
    font-weight: bold;
    font-size: 1.5rem;
    color: #1D3948;
}

.carousel{
    width: 85%;
    z-index: 2;
    position: fixed;
    left: 9%;
    top: 5%;
    top: 5;
    margin: auto;
    background-color: #fff;
}

.del-drawer{
    margin-top:40px;
   margin-left: 0px !important;
}

.info{
    font-size: 1.6rem;
    margin-bottom: 20px;
    font-weight: bold;
}


.compare-table{
    margin-top: 24px !important;

    @media (max-width: 992px) {
        .carousel{
            width: 85%;
            margin: 80px auto 0px auto;
        }
    }
}

.react-multi-carousel-item{
    margin-right: 10px;
    // width: unset !important;
}

@media (max-width: 992px) {

    .carousel{
        width: 85%;
        margin: 80px auto 0px auto;
    }
}
@media (max-width: 420px) {
    .review-card{
        width: 100%;
    }
}

.chart-report-tabs{
    @media (max-width: 961px) {
        margin-top: 80px !important;
    }
}

.rate-contain{
    @media (max-width: 961px) {
        flex-direction: column;
    }
}

.launch-date{
    font-weight: bold;
    color: #1D3948;
    font-size:2rem;
}

.review-instructions{
    margin-top: 16px;
    margin-bottom: 16px;
}
.final-rating{
    margin-top: 10px;
}

.ant-tabs-tab .ant-tabs-tab-active {
    color: #000 !important; 
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
    color: #000 !important; 
    font-weight: bold !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar{
    border-bottom: 2px solid #000;
}

.welcome-closing-messsage {
    background-color: #f8f9fa;
    padding: 15px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

    .opening-msg {
        font-weight: 700;
        font-size: 2.1rem;
        color: #1D3948;
    }


.ant-btn>span {
    display: inline-block;
}
.survey-response-area {
    max-width: 900px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
  
    .question {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px solid #e6e9ee;
      transition: all 0.2s ease-in-out;
  
      &:hover {
        border-color: rgba(30, 125, 121, 0.3);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
      }
  
      .question-top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
  
        .question-title {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1D3948;
          flex-grow: 1;
          position: relative;
          padding-left: 15px;
  
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 4px;
            height: 70%;
            background-color: var(--color-primary);
            border-radius: 2px;
          }
        }
      }
  
      .question-element {
        .ant-input,
        .ant-select,
        .ant-radio-group,
        .ant-upload {
          width: 100%;
          border-radius: 6px;
          transition: all 0.2s ease;
        }
  
        .ant-input, 
        .ant-select-selector {
          &:focus, 
          &:hover {
            border-color: var(--color-primary);
            box-shadow: 0 0 0 2px rgba(30, 125, 121, 0.1);
          }
        }
  
        .multi-choice {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
  
          .ant-radio-wrapper {
            margin-right: 10px;
            padding: 8px 12px;
            border-radius: 6px;
            border: 1px solid #e9ecef;
            transition: all 0.2s ease;
  
            &.ant-radio-wrapper-checked {
              border-color: var(--color-primary);
              background-color: rgba(30, 125, 121, 0.05);
            }
          }
        }
      }
    }
  
    .welcome-closing-messsage {
      background-color: #f8f9fa;
      border-left: 5px solid var(--color-primary);
      padding: 15px 20px;
      margin: 25px 0;
      border-radius: 6px;
  
      .opening-msg {
        color: #1D3948;
        font-weight: 500;
        line-height: 1.6;
      }
    }
  
    .next-survey-btn {
      display: block;
      width: 200px;
      margin: 30px auto;
      border-radius: 8px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
      background-color: var(--color-primary);
      color: white;
      border: none;
      transition: all 0.3s ease;
      box-shadow: 0 4px 10px rgba(30, 125, 121, 0.2);
  
      &:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 15px rgba(30, 125, 121, 0.3);
      }
  
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        transform: none;
        box-shadow: none;
      }
    }
  }
  
  @media (max-width: 768px) {
    .survey-response-area {
      padding: 15px;
      margin: 20px 10px;
  
      .question {
        padding: 15px;
  
        .question-top .question-title {
          font-size: 1.1rem;
        }
      }
    }
  }
@primary-color: #d8232a;@text-selection-bg: #ACCEF7;