.orderCon {
  background-color: var(--color-background);
  width: 100%;
  padding: 1em 1em;
}


@media (min-width: 62em) {
  .orderCon {
    display: flex;
    align-items: flex-start;
  }
}

.orderInfoCon {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #707070;
}

@media (min-width: 62em) {
  .orderInfoCon {
    border-right: 1px solid #707070;
    border-bottom: none;
    width: 50%;
    display: inline-block;
    padding-left: 2em;
    padding-right: 2em;
  }
}

.orderInfoCon:last-child {
  border-bottom: none;
}

@media (min-width: 62em) {
  .orderInfoCon:last-child {
    border-right: none;
  }
}

/* 
  ADDRESS INFO
*/

.addrHeader {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  display: block;
  margin-bottom: 1em;
}

.addrValueCon {
  display: flex;
  margin-bottom: 0.5em;
}

.addrTitle {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  display: block;
}

.addrTitle {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  width: 50%;
  /* padding-left: 1em; */
  display: inline-block
}

.addrValue {
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  width: 50%;
  display: inline-block
}

/* 
  PAYMENT
*/

.paymentValueCon {
  margin-bottom: 0.5em;
}

.paymentHeader {
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  display: block;
  /* margin-top: 1em; */
  margin-bottom: 1em;
}

.paymentCardIcon {
  width: 2.2em;
  margin-right: 1em !important;
}

.paymentCardIconCon { 
  display: flex;
}

.paymentProvider {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.paymentTitle {
  font-size: 1.2rem !important;
  display: block;
}

.paymentValue {
  font-size: 1.6rem !important;
  display: block;
}

.cardIcon {
  font-size: 2.8rem;
}
