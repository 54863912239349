.headerText {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.contentCon {
  background-color: none;
  border-radius: 8px;
  padding: 0em;
}

@media (min-width: 62em) {
  .contentCon {
    background-color: var(--color-background);
    padding: 2em;
  }
}

.userInfoCon {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 0.5px solid #C7C7C7;
}

@media (min-width: 42em) {
  .userInfoCon {
    padding-bottom: 2em;
  }
}

.userAvatar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 3em !important;
  width: 3em !important;
}

.userAvatar * {
  color: var(--color-text-in-primary);
  font-size: 3.0rem !important;
}

.userInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  min-width: 60%;
}

.userName {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.userEmail,
.userPhone,
.userAddress {
  font-size: 1.1rem !important;
  color: #9A9A9A !important
}

.addAddressCon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

@media (min-width: 42em) {
  .addAddressCon {
    width: auto;
    align-self: flex-end;
  }
}

button.addAddress {
  /* width:  */
  padding-left: 2em !important;
  padding-right: 2em !important;
}

/*
  FORM
*/

/* .formCon {
} */

.getInTouchCon {
  margin-top: 2em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 62em) {
  .getInTouchCon {
    width: 90%;
  }
}

.getInTouchText {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-top: 2em !important;
  display: block;

}

/* .getInTouchRadioGrp {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
} */

/* .getInTouchRadioGrp *  {
  font-size: 1.4rem !important;
  font-weight: 500;
} */

@media (min-width: 62em) {
  .getInTouchCon {
    margin-bottom: 0em;
  }
}

.getInTouchFormCon {
  display: block;
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .getInTouchFormCon {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
  }

  .getInTouchFormConResponsive {
    display: inline-block;
    margin-left: 0em;
  }

  .formCon .getInTouchFormItems .getInTouchFormConResponsive:nth-child(2n) {
    margin-left: 1em;
  }

  .getInTouchFormConTextArea {
    width: 100%;
  }
}

.getInTouchLabel {
  font-size: 1.4rem !important;
  display: block;
  margin-bottom: 0.5em;
}

.getInTouchNote {
  color: var(--color-primary) !important;
  font-size: 0.9rem !important;
  display: block;
  margin-bottom: 0.5em;
}

button.resetPassword {
  padding: 0.5em 0 !important;
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.resetPassword * {
  color: var(--color-primary) !important;
  font-weight: 600 !important;
  font-size: 1.6rem !important;
}

.getInTouchCommsCon {
  /* margin-top: 1em; */
  margin-bottom: 2.5em;
}

.getInTouchComms {
  font-size: 1.4rem !important;
  font-weight: 500;
}



.select {
  margin-top: 2em !important;
  width: 100%;
  height: 3em !important;
  background: none !important;
  border-radius: 0.5em !important;
}
.select * {
  text-align: left;
  height: 100% !important;
  border-radius: 0.5em !important;
  background: none !important;
}

.select span {
  display: flex;
  align-items: center;

}

@media (min-width: 62em) {
  .select{
    width: 60% !important;
    left: 4% !important;
  }
}


.addAddress{
  background-color:  var(--color-background) !important ;
  border-radius: var(--color-background) !important;
}

.inputDisabled {
  color: #ccc !important;
}

.linkButton{
  display: flex;
  flex-direction: row;
}

