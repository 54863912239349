.inviteBtn {
  /* margin: 1em 0; */
}

/* 
    MODAL FORM
*/
.checkoutModal {
  width: 46em !important;
}

.formModalBody {
  display: flex;
  justify-content: space-between;
}
