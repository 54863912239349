
.lastOrderCon {
  background-color: var(--color-background);
  padding: 1em 1em;
  width: 100%;
  overflow-x: auto; 
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 1em;
  border-radius: 8px;
}

.lastOrderInfo {
  margin-bottom: 1em;
}

.lastOrderInfoHeader {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .lastOrderInfoHeader {
    font-size: 1.5rem !important;
  }
}

.lastOrderInfoOrderId {
  font-size: 1.1rem !important;
  margin-left: 0.5em !important;
}

@media (min-width: 62em) {
  .lastOrderInfoOrderId {
    font-size: 1.5rem !important;
  }
}

.lastOrderCurrent {
  margin-top: 1em;
}

.lastOrderInfoStatus {
  font-size: 1.1rem !important;
  color: #40EAA2 !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .lastOrderInfoStatus {
    font-size: 1.5rem !important;
  }
}

.checkoutSteps {
  padding: 0em;
}

@media (min-width: 62em) {
  .checkoutSteps {
    padding: 1em !important;
  }
}

.lastOrderCurrentHeader {
  display: block;
  font-size: 1.4rem !important;
  font-weight: 500;
}

.lastOrderCurrentDate {
  display: block;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  color: #9A9A9A !important; 
}

.checkoutStepsTitle,
.checkoutStepsSubTitle {
  display: none !important;
}

@media (min-width: 62em) {
  .checkoutStepsTitle {
    text-align: center !important;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    display: block !important;
    line-height: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .checkoutStepsSubTitle {
    font-size: 1.1rem !important;
    text-align: center !important;
    display: block !important;
  }
}

.lastOrderCurrent {
  display: block;
}

@media (min-width: 62em) {
  .lastOrderCurrent {
    display: none;
  }
}

