.vacancy-info-container {
  @color-background: #f5f5f5;
  @color-primary: #D8232A;
  @color-primary-hover: #fb040c;
  @color-secondary: #f0f0f0;
  @color-text: #333;
  @color-text-light: #666;
  @color-border: #e0e0e0;

  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
  }

  .vacancy-info-header {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid @color-border;
  }

  .vacancy-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    color: @color-primary;
  }

  .vacancy-meta-info {
    margin-bottom: 1.5rem;
  }

  .meta-items {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .meta-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: @color-text-light;
    font-size: 1.4rem;

    .anticon {
      color: @color-primary;
    }
  }

  .action-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .apply-button {
    height: 48px;
    padding: 0 2rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .secondary-actions {
    display: flex;
    gap: 0.5rem;

    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .vacancy-info-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 1.5rem;
    line-height: 1.6;
    color: @color-text;
  }

  .vacancy-info-content ul {
    list-style-type: disc; 
    margin-left: 40px; 
  }

  .vacancy-info-content ol {
    list-style-type: decimal; 
    margin-left: 40px; 
  }
  
}


@primary-color: #d8232a;@text-selection-bg: #ACCEF7;