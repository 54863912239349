
/* 
  CTA
*/

* {
  text-align: left;
}

.ctaSubText {
  display: block;
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .ctaSubText {
      width: 70% !important;
  }
}

.playBtnCon {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.playBtnText {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.rectangle {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .rectangle {
        width: 24;
    }
}
.iconContainer { 
    display: flex;
    padding-right: 10px !important; 
}
@media (min-width:62em){
  .iconContainer{
    position: absolute;
    top: 3em;
  }
}
button.playBtnText * {
    font-weight: 500 !important;
}

/* 
    ABOUT US
*/

.aboutUsCon {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .aboutUsCon {
    height: 50em;
    justify-content: flex-end;
  }
}

.aboutUsContent {
  padding: 6em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .aboutUsContent {
    width: 70%
  }
}

@media (min-width: 82em) {
  .aboutUsContent {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.aboutUsImg {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .aboutUsImg {
    display: inline-block;
  }
}

.aboutUsHeader,
.productOfferHeader,
.valuesHeader,
.valuesMissionHeader,
.clientsHeader {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
}

@media (min-width: 82em) {
  .aboutUsHeader,
  .productOfferHeader,
  .valuesHeader,
  .valuesMissionHeader {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.aboutUsText {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .aboutUsText {
    font-size: 1.6rem !important;
    text-align: left;
  }
}


/* 
  PRODUCTS OFFER
*/

.productOfferCon {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .productOfferCon {
    padding: 6em 7em;
  }
}

.productOfferText {
  font-size: 1.6rem !important;
  text-align: left;
  width: 100%;
}

@media (min-width: 62em) {
  .productOfferText {
    width: 50%;
  }
}

.productOfferItemCon {
  margin-top: 4em;
}

.productOfferItem {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  padding: 0em 1em;
  width: calc(50% - 0.5em);
  margin-bottom: 1em;
  min-height: 15em;
}

@media (min-width: 62em) {
  .productOfferItem {
    width: calc(20% - 2em);
    margin-right: 2.5em;
    margin-bottom: 2.5em;
    min-height: 20em;
  }

  .productOfferItem:nth-child(5n) {
    margin-right: 0em;
  }
}

.productOfferItem:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 62em) {
  .productOfferItem:nth-child(2n) {
    margin-left: 0em;
  }
}

.productOfferItemIcon {
  font-size: 5rem;
  padding: 1em 25% 0.5em 25%;
  border-bottom: 0.1em solid var(--color-primary);
}

@media (min-width: 62em) {
  .productOfferItemIcon {
    font-size: 7rem;
    border-bottom: 0.05em solid var(--color-primary);
  }
}

.productOfferItemName {
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: center !important;
  height: 4rem !important;
  padding: 1em 0em !important;
}

@media (min-width: 62em) {
  .productOfferItemName {
    padding: 2em 0em !important;
    font-size: 1.4rem !important;
  }
}



/* 
  DELIVERIES
*/

.deliveriesCon {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 82em) {
  .deliveriesCon {
    height: 45em;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.deliveriesContent {
  padding: 6em 1em;
  background-color: #343433;
}

@media (min-width: 62em) {
  .deliveriesContent {
    width: 70%
  }
}

@media (min-width: 82em) {
  .deliveriesContent {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 43%;
    padding: 8em 8em;
  }
}

.deliveriesImg {
  height: 28em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 82em) {
  .deliveriesImg {
    display: inline-block;
    height: 100%;
    width: 57%;
    background-position: 0% 0%;
  }
}

.deliveriesHeader {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: left;
  color:  var(--color-text-in-secondary) !important;
}

@media (min-width: 82em) {
  .deliveriesHeader {
    font-size: 4.6rem !important;
    margin-bottom: 1em !important;
  }
}

.deliveriesText {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: left;
  color:  var(--color-text-in-secondary) !important;
}

@media (min-width: 82em) {
  .deliveriesText {
    font-size: 1.6rem !important;
  }
}

/* 
  VALUES SECTION
*/

.valuesCon {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .valuesCon {
    padding: 6em 7em;
  }
}

.valuesHeader {
  text-align: left !important;
  margin-bottom: 1em !important;
}

.valuesItemCon {
  display: block;
}

@media (min-width: 43em) {
  .valuesItemCon {
    display: flex;
    justify-content: space-between;
  }
}

.valuesItem {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  width: 100%;
}

@media (min-width: 43em) {
  .valuesItem {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    width: calc(33.33% - 4em)
  }
}

.valuesItemTitle {
  font-size: 2.6rem !important;
  font-weight: 500 !important;
}

.valuesItemTitleLine {
  background-color: var(--color-primary);
  height: 0.5em;
  width: 5em;
  margin-top: 0.6em;
  margin-bottom: 2.5em;
}

.valuesItemText {
  text-align: left;
  font-size: 1.4rem !important;
}

.valuesMissionCon {
  display: block;
}

@media (min-width: 82em) {
  .valuesMissionCon {
    display: flex;
  }
}

.valuesMissionImg {
  height: 20em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .valuesMissionImg {
    display: inline-block;
    height: 50em;
    width: 43%;
  }
}

.valuesMissionContent {
  padding: 4em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 82em) {
  .valuesMissionContent {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 4em 4em;
    padding-right: 2em;
  }
}

.valuesMissionHeader {
  text-align: left !important;
  margin-bottom: 1em !important
}

.valuesMissionText {
  text-align: left;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .valuesMissionText {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 85%;
  }
}

.valuesMissionThinkTextCon {
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .valuesMissionThinkTextCon {
    flex-direction: row;
  }
}

.valuesMissionThinkText {
  color: var(--color-primary) !important;
  text-align: left;
  font-size: 2.2rem !important;
  font-weight: 700 !important;
}

@media (min-width: 62em) {
  .valuesMissionThinkText {
    margin-right: 1em;
    margin-top: 1em;
  }

  .valuesMissionThinkText::before {
    content: '\2022';
    margin-right: 0.5em;
  }

  .valuesMissionThinkText:first-child::before {
    display: none;
  }
}

/* 
  CLIENT LIST
*/

.clientsCon {
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .clientsCon {
    padding: 2em 7em;
  }
}

.clientsHeader {
  text-align: left !important;
  margin-bottom: 1em !important;
}

.clientsText {
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .clientsText {
    margin-bottom: 4em !important;
  }
}

.clientsIconCon {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 2em;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .clientsIconCon {
    display: block;
    overflow-x: initial;
    padding: 0em 7em;
  }
}

.clientsIconItemCon {
  display: inline-block;
  width: auto;
}

@media (min-width: 62em) {
  .clientsIconItemCon {
    width: 25% !important;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 4em;
  }
}

img.clientsIcon {
  height: 4.7em;
  margin-left: 4em;
}

@media (min-width: 62em) {
  img.clientsIcon {
    margin-left: 0em;
  }
}

.responsiveBackground {
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
}
