.loginCon {
  height: auto;
  padding: 2em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .loginCon {
    padding: 0em 0em;
    display: flex;
    flex-direction: row-reverse;
    min-height: calc(100vh - 6em);
  }
}

.loginFormCon {
  display: block;
  width: 100%;
  padding: 0em;
}

@media (min-width: 62em) {
  .loginFormCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60% !important;
    padding: 4em 8em;
  }
}

.loginFormTitle {
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .loginFormTitle {
    font-size: 5.4rem !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.loginFormSubText {
  padding-bottom: 2em;
}

.loginFormOptsCon {
  margin-top: 2em;
}



@media (min-width: 62em) {
  .loginFormOptsCon {
    margin-top: 1em;
  }
}

.loginFormOptsCon button > * {
  text-wrap: wrap;
  text-align: center;
}

.loginFormOptsMigrateText {
  font-size: 1.2rem !important;
  margin-top: 1em;
}

.loginAltOpts {
  display: flex;
  flex-direction: column;
}

.loginAltOpts button:first-child {
  margin-bottom: 1em!important;
  margin-right: 1em!important;
}

.loginAltOpts button > * {
  text-wrap: wrap;
  text-align: center;
}

.loginFormBanner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  margin-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 70%;
}

@media (min-width: 62em) {
  .loginFormBanner {
    flex: 1;
    margin-top: 0em;
    height: inherit;
  }
}

.loginFormBanner ul li:before {
  content: '✓ ';
  margin-right: 1em;
}

.loginFormBanner ul {
  list-style: none;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 62em) {
  .loginFormBanner ul {
    font-size: 2.0rem;
  }
}



.bannerText {

  color: white !important;

}

.bannerTextCon{
  margin-bottom: 2em;
}


