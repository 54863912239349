 
  .con{
    width: 80% !important;
    margin: 0 auto;
    padding: 4em 1em;
    justify-content: center;
  }
  
  
  .productResultCardCon {
    text-align: left;
  }
  
  .productResultCard {
    display: inline-block;
    width: calc(50% - 0.5em) !important;
    margin-bottom: 1em;
  }
  
  @media (min-width: 82em) {
    .productResultCard {
      width: calc(33.33% - 1em) !important;
    }
  }
  
  .productResultCard:nth-child(2n) {
    margin-left: 1em;
  }
  
  @media (min-width: 82em) {
    .productResultCard:nth-child(2n) {
      margin-left: 0em;
    }
  
    .productResultCard {
      margin-right: 1em;
    }
  }
  
  /* .productResultCard:nth-child(3n) {
    margin-left: 1em;
  } */
  
  .productResultPName {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
  
  @media (min-width: 43em) {
    .productResultPName {
      font-size: 1.6rem !important;
    }
  }
  
  .productResultImg {
    height: 12em !important;
  }
  
  @media (min-width: 62em) {
    .productResultImg {
      height: 19em !important;
    }
  }
  
  .emptyList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8em 0em;
  }
  .productShowcaseCon {
    padding: 6em 1em;
  }
  
  @media (min-width: 62em) {
    .productShowcaseCon {
      padding: 2em 7em;
    }
  }
  .productHeader {
    font-size: 1.8rem !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 0.5em !important;
    text-align: center;
    margin-top: 1em;
  }
  
  @media (min-width: 82em) {
    .productHeader{
      font-size: 2.5rem !important;
      text-align: center;
      margin-top: 2em !important;
    }
  }

.searchSpinCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  