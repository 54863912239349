/* styles.module.css */

.container {
    display: flex;
    align-items: center;
    width:100%;
}

.LoyaltyCard {
    position: relative; 
    border-radius: 1.5em !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 25rem !important;
    margin: 0 auto;
    overflow: hidden;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url("../../assets/loyalty-card-barcode.jpg") !important;
}

.LoyaltyCard:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.loyaltyCardHeader {
    position: relative;
    border-top-left-radius: 0.2em; 
    border-top-right-radius: 0.2em; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    margin-top: -2em !important;
}

.foxImageContainer
{
    display: flex;
    flex-direction: column;
}

.foxImage {
    width:30%;
    height: 5em; 
    display: block;
    margin-top: 0em !important;
}

.foxText {
    margin-top:-2em; 
    font-style: italic;
    font-weight: bold;
}
.bidvestImage {
    width: 4em;
    height: 4em; 
    display: block;
    margin: 0 !important;
}

.loyaltyCardBody {
    text-align: center;
}

.fullName {
    margin: 0;
    font-size: 1.5em;
    color: #333;
}

.cardNumber {
    margin-top: 1em;
    font-size: 1.2em;
    color: #555; 
    text-align: center;
}

.websiteLink {
    margin-top: 0.5em;
    font-size: 1.2em;
    color: red; 
    font-weight: bold;
    text-align: center;
}
