
.headerText {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}
  
  @media (min-width: 62em) {
    .welcomeText {
      font-size: 2.0rem !important;
    }
  }

  .cardCon {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1em;
  }
  
  @media screen and (max-width: 768px) {
    .cardCon {
      flex-direction: column;
      gap:1em;
    }
  }
  
.searchDatePickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 62em) {
  .searchDatePickerContainer {
    flex-direction: row;
  }
}

