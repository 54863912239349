.con {
  background-color: var(--color-background);
  padding: 1em 1em;
  border-radius: 8px;
  position: relative;
}

.headerCon {
  display: flex;
  justify-content: space-between;
}

.headerText {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .headerText {
    font-size: 1.5rem !important;
  }
}

button.historyBtn,
button.reorderBtn {
  height: 2em;
  width: 6em;
}

button.historyBtn * ,
button.reorderBtn * {
  font-size: 1.1rem !important;
}

button.reorderBtn {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.statusCon {
  display: flex;
}

.statusConMiddle {
  height: 8em;
}

.statusIconCon {
  width: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusIcon {
  font-size: 2.8rem
}

.statusInfoCon {
  padding-left: 1em;
  flex: 1;
}

.statusConMiddle .statusInfoCon {
  padding: 0.5em 1em;
  overflow-y: scroll
}

.statusInfoHeader {
  font-size: 1.3rem !important;
  display: block;
}

.statusInfoOrderDate {
  font-size: 1.1rem !important;
  font-weight: 300 !important;
  display: block;
}

.statusInfoProduct {
  display: block;
  font-size: 1.1rem !important;
}


.statusLine {
  border-left: 3px dotted #9A9A9A;
  height: 100%
}

.statusPriceCon {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5em;
  height: 100%;
}

.statusPrice {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}
