/*
  INPUT
*/
.input {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  font-size: 1.2rem !important;
}

.inputRounded {
  border-radius: 8px !important;
}

.input * {
  background: none !important;
}

.input::placeholder {
  color: #656565;
}

/*
  SEARCH
*/

.searchInput {
  border: none !important;
  border-bottom: 1px var(--color-separator-light) solid !important;
  padding-left: 0em !important;
  border-radius: 0em !important;
  cursor: input;
  background: none !important;
}

.searchInput input::placeholder {
  font-size: 1.1rem;
}

.searchInput > * {
  background: none !important;
}


/*
  SELECT
*/

.selectCon {
  display: flex;
  width: 100%;
  height: 4em;
  position: relative;
}

@media (min-width: 62em) {
  .selectCon {
    height: 3em;
  }
}

.select {
  width: 100%;
  height: 100% !important;
  border: 1px solid #d9d9d9  !important;
  border-radius: 0em;
}

.selectRounded .select {
  border-right: none !important;
  border-radius: 8px;
}

.select * {
  text-align: left;
  height: 100% !important;
  border: none !important;
}

.select span {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1.4rem !important;
  color: #9A9A9A !important;
}

@media (min-width: 62em) {
  .select span {
    font-size: 1.4rem;
  }
}

.selectSuffixBtn {
  height: 100% !important;
  background-color: var(--color-primary);
  /* position: absolute !important; */
  height: 100%;
  /* right: 0; */
  border-radius: 0 !important;
}

.selectRounded button.selectSuffixBtn {
  border-radius: 8px !important;
}

.selectSuffixBtnIcon {
  font-size: 2.4rem !important;
}

@media (min-width: 62em) {
  .selectCon {
    height: 3.5em;
  }
}

/*
  NEWSLETTER
*/

.subscribeNewsletterCon {
  display: flex !important;
  height: 4em;
}

@media (min-width: 62em) {
  .subscribeNewsletterFooterCon {
    height: 3em;
  }
}


.subscribeNewsletterInput {
  flex: 1;
  height: 100%;
  font-size: 1.4rem !important;
  background-color: #fff !important;
}

.subscribeNewsletterInput::placeholder {
  font-size: 1.4rem;
}

.newsletterFooterInput {
  font-size: 1.1rem !important;
}

.newsletterFooterInput::placeholder {
  font-size: 1.1rem;
}

button.subscribeNewsletterBtn {
  height: 100% !important;
}

button.subscribeNewsletterBtn * {
  font-weight: 700 !important;
}
/*
.subscribeNewsletterBtnIcon {

} */

/*
  TEXT AREA
*/

.inputTextArea {
  height: 16em !important;
}
