
/*
  DELIVERY PRICING
*/

.deliveryHeader {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 1em 0em;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.deliveryOptsCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.deliveryOptsCon * {
  font-size: 1.3rem !important;
}

.deliveryOptsConActive * {
  font-weight: 500 !important;
}

.deliveryOpts {
  flex: 1 !important;
  display: flex;
  align-items: center;
}

.deliveryOptsIcon {
  font-size: 1.1rem;
  margin: auto 0.5em;
  color: var(--color-background)
}

.deliveryOptsConActive .deliveryOptsIcon  {
  color: inherit;
}


.infoContainer {
  /* padding: 1.5em; */
  text-align: left;
  position: relative;
}

.infoProdNameCon {
  position: relative !important;
}

.infoProdNameCon .viewMoreBtn {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 43em) {
  .infoProdNameCon .viewMoreBtn {
    display: none;
  }
}


.totalCon{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.totalCon .viewMoreBtn {
  display: none;
}

@media (min-width: 43em) {
  .totalCon{
    justify-content: space-between;
  }

  .totalCon .viewMoreBtn {
    display: inline-flex;
  }
}

.OrderMonitorView {
  padding: 1em;
}

@media (min-width: 43em) {
  .OrderMonitorView {
    padding: 1.5em;
    display: inherit !important;
  }
}

.trackOrder{
  flex-direction: row !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  margin-left: 0.5em;
}

@media (min-width: 43em) {
  .cancelOrder{
  }

  .trackOrder{
  }
}

.btnCon {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 1em ;
}

@media (min-width: 43em) {
  .btnCon {
    margin-top: 0em ;
    position: absolute;
    right: 0;
    top: 0;
  }
}


