.tabCon {
  padding: 1em 1em;
  background-color: #FFFFFF;
  position: relative;
}

.historyHeader {
  align-self: center;
  color: #343433 !important;
  font: Poppins,bold;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}
@media (min-width: 62em) {
  .historyHeader {
    font-size: 5rem !important;
  }
}
.historyYears{
  align-self: center;
  color: #000000;
  font: Poppins,bold;
  font-size: 6.0rem !important;
  font-weight: 800 !important;
  margin-bottom: 0em !important;
}
@media (min-width: 62em) {
  .historyHeader {
    font-size: 2.8rem !important;
  }
}

.historyConRight {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .historyConRight {
    flex-direction: row;
    height: 40em;
  }
}
@media (min-width: 62em) {
  .historyConRight {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}
.historyConLeft {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: relative;
  left:1em
}


@media (min-width: 43em) {
  .historyConLeft {
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .historyCon {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}

.otherOfferItemBgRight {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .otherOfferItemBgRight {
    width: 55%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .otherOfferItemBgRight {
    width: 55%;
  }
}


.otherOfferItemBgLeft {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
  position: relative;
  left:1em;
}

@media (min-width: 43em) {
  .otherOfferItemBgLeft {
    width: 58%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .otherOfferItemBgLeft {
    width: 58%;
  }
}
.historyItemConRight {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .historyItemConRight {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
  }
}

.historyItemConLeft {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  left: 1em;
}
.historyItemConLeftLast {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  left: 1em;
}
@media (min-width: 43em) {
  .historyItemConLeft {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
  }
    .historyItemConLeftLast {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
    width: 50%;
    left: 50%;
    top: 13%;
  }

}


@media (min-width: 62em) {
  .historyItemConLeftLast {
    padding: 3em 2em;
    background-color: #FFFFFF;
    text-align: left;
    position: relative;
    left: 1em;
    width: 100%;
    top: 30%;
  }

}



.otherOfferItemBg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
}

@media (min-width: 43em) {
  .otherOfferItemBg {
    width: 30%;
    height: 100%;
  }
}

.timeLineCon{
  position: relative;
  top:10em
}

.historyConRightFirst{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 23em;
}

@media (min-width: 43em) {
  .historyConRightFirst {
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .historyConRightFirst {
    height: 40em;
    padding-left: 7em;
    padding-right: 0em;
  }
}


.historyConRightFirst::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 92px;
    margin-bottom: -1em;
}

.historyConRightLast{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}

@media (min-width: 43em) {
  .historyConRightLast {
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .historyConRightLast {
    height: 40em;
    padding-left: 50em;
    padding-right: 5em;
  }
}



.historyConRightLast::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}
@media (min-width: 43em) {
    .historyConRightLast::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: -8.5%;
    left: 50%;
    margin-left: -2px;
    height: 57.5em;
    margin-bottom: -1em;
  } 
}
@media (min-width: 62em) {
  .historyConRightLast::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: -8.5%;
    left: 50%;
    margin-left: -2px;
    height: 57.5em;
    margin-bottom: -1em;
  }
}



.historyConRight{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}

@media (min-width: 43em) {
  .historyConRight {
    display: flex;
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .historyConRight {
    height: 40em;
    padding-left: 2em;
    padding-right: 0em;
  }
}

.historyConRight::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}


.historyConLeft{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}
@media (min-width: 43em) {
  .historyConLeft {
    display: flex;
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .historyConLeft {
    height: 40em;
    padding-left: 7em;
    padding-right: 5em;
  }
}

.historyConLeft::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}
@media (min-width: 43em) {
   .historyConLeft::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
     .historyConRight::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
}
@media (min-width: 62em) {
    .historyConLeft::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
    .historyConRight::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
}

/* The circles on the timeline */
.historyConLeft::after {
   content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 46.5% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}

@media (min-width: 43em) {
  .historyConLeft::after {
    left: 48.5% !important;
  }

}

@media (min-width: 62em) {
    .historyConLeft::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49.0% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}


/* The circles on the timeline */
.historyConRight::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 47% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}
@media (min-width: 43em) {
    .historyConRight::after {
    left: 48.5% !important;
  }
}

@media (min-width: 62em) {
    .historyConRight::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}

.historyConRightLast::after{
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 46.5% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}
@media (min-width: 43em) {
    .historyConRightLast::after{
    left:48.5% !important;
  }
}
@media (min-width: 62em) {
      .historyConRightLast::after{
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}

.years1968{
  position: absolute;
  left: 13.5em;
  /* top: 51em; */
  top: 17.3%;
  Z-INDEX: 1;
  background-color: #FFFFFF;
  color: #D8232A !important;
  line-height: -12em;
  font-weight: 600;
}




.yearsConLeft{
   position: absolute;
    Z-INDEX: 1;
    left: 42.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .yearsConLeft{
    left:46.5%;
  }
}
.yearsConRight{
   position: absolute;
    Z-INDEX: 1;
    left:49.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .yearsConRight{
    left:49.5%;
  }
}



.yearsConRightBeginning{
   position: absolute;
    Z-INDEX: 1;
    left:45.5%;
    margin-top: 2.4em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .yearsConRightBeginning{
    left:48%;
  }
}


.yearsConLeftEnd{
   position: absolute;
    Z-INDEX: 1;
    left: 45.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .yearsConLeftEnd{
    left:48%;
  }
}

.years2010{
    position: absolute;
    left: 13.8em;
    /* top: 51em; */
    top: 81.7%;
    Z-INDEX: 1;
    background-color: #FFFFFF;
    color: #D8232A !important;
    line-height: -12em;
    font-weight: 600;
}

.years2011{
    position: absolute;
    left: 11.5em;
    /* top: 51em; */
    top: 31.8%;
    Z-INDEX: 1;
    background-color: #FFFFFF;
    color: #D8232A !important;
    line-height: -12em;
    font-weight: 600;
}
.yearsRight{
  position: absolute;
  Z-INDEX: 1;
  left: 61.5%;
  margin-top: -2em;
}

.yearsLeft{
    position: absolute;
    Z-INDEX: 1;
    left: 0.5%;
    margin-top: -2em;
}
.yearsLeftLast{
    position: absolute;
    Z-INDEX: 1;
    left: 0.5%;
    margin-top: -2em;
}

@media (min-width: 43em) {
    .yearsLeftLast{
      position: absolute;
      Z-INDEX: 1;
      left: 0.5%;
      margin-top: 6em;
  }
}
@media (min-width: 62em) {
    .yearsLeftLast{
      position: absolute;
      Z-INDEX: 1;
      left: 0.5%;
      margin-top: -2em;
  }
}

@media (min-width: 43em) {

  .yearsLeft{
   position: absolute;
    Z-INDEX: 1;
    left: 2.5%;
    margin-top: -6em;
  }
   .yearsRight{
    position: absolute;
    Z-INDEX: 1;
    left: 50.5%;
    margin-top: -7em;
   }

    .historyYears{
    align-self: center;
    color: #000000;
    font: Poppins,bold;
    font-size: 15.0rem !important;
    font-weight: 800 !important;
    margin-bottom: 0em !important;
  }

     
}
@media (min-width: 62em) {
   .yearsLeftLast{
   position: absolute;
    Z-INDEX: 1;
    left: 8.5%;
    margin-top: 15em;
  }
   .yearsRight{
    position: absolute;
    Z-INDEX: 1;
    left: 55.5%;
    margin-top: -7em;
   }
}
.line{
  width: 15%;
  height: 1px;
  left: 42%;
  position: absolute;
  margin-top:4.3em;
  background: rgb(6, 6, 6);
}

.lineEnd{
  width: 15%;
  height: 1px;
  left: 42%;
  position: absolute;
  margin-top:-7.6em;
  background: rgb(6, 6, 6);
}


.yearText{
  align-self: center;
  color:#D8232A !important;
  font: Poppins,bold;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

.vLine{
    border-left: 1px solid #000;
    height: 100px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 96.5%;
}

@media (min-width: 62em) {
  .vLine {
    display: none;
  }
}
@media (min-width: 43em) {
  .vLine {
    display: none;
  }
}

@media (min-width: 62em) {
  .yearsConRight {
    display: none;
  }
  .yearsConLeft {
    display: none;
  }
  .yearsConRightBeginning {
    display: none;
  }
   .yearsConLeftEnd {
    display: none;
  }
}
@media (min-width: 43em) {
  .yearsConRight {
    display: none;
  }
  .yearsConLeft {
    display: none;
  }
  .yearsConRightBeginning {
    display: none;
  }
   .yearsConLeftEnd {
    display: none;
  }
}