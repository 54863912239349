.container {
    display: flex;
    align-items: center;
    width:100%;
}

.cardContainer {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url("../../assets/loyalty_card_back.jpg") !important;
    width: 100% !important;
    height: 25rem;
    border-radius: 1.5rem !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    position: relative;
    overflow: hidden;
  }
  .cardContainer:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
  
  .foxLogo {
    width: 8em ;
    height: 8em ;
    margin-top: -2.3em;
  }
  
  .cardText {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .cardText h1 {
    font-size: 24px;
    margin: 0;
    font-weight: normal;
    color: whitesmoke;
  }
  .cardTopText {
    position: absolute;
    top: 4em !important;
    left: 2em !important;
  }
  .cardTopText h2 {
    font-size: 15;
    margin-top: 0em;
    font-weight: normal;
    color: whitesmoke;
  }
  