.tabCon {
  padding: 1em 1em;
  background-color: #F7F7F7;
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
  margin-bottom: 0em !important;
}

.registerHeader {
  align-self: center;
  color: #343433 !important;
  font: Poppins,bold;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .registerHeader {
    font-size: 2.8rem !important;
  }
}

@media (min-width: 62em) {
  .tabCon {
    padding: 1em 7em 4em 7em;
    background-color: #F7F7F7;
  }
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.contactDetailsCon {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
} 

@media (min-width: 43em) {
  .contactDetailsCon {
    width: 40em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.contactDetailsCon hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.phoneBtn {
  flex: 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.phoneBtnIcon {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}


/* 
  DETAILS
*/

.detailsCon {
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .detailsCon {
    width: 90%;
  }
}

.detailsConHeader {
  display: flex;
  align-items: center;
}

.detailsText {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0 !important;
}



@media (min-width: 62em) {
  .registerFormCon{
    margin-bottom: 0em;
  }
}

.registerFormCon{
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .registerFormCon {
    margin-bottom: 0em;
    margin-right: 1em;
  }

  .registerFormConInput {
    display: inline-block;
    width: calc(50% - 1em);
  }

  .registerFormConInput:nth-child(2n) {
    margin-right: 0em;
  }

  .registerFormConTextArea{
    width: 100%;
  }
}


.registerLabel {
  font-size: 1.125rem !important;
}
.registerredSmall{
  color:#D8232A !important;
  font-size: 1.2rem !important;
}

.registerLabelred{
  color:#D8232A !important;
  font-size: 1.4rem !important;
}

.registerLabelredBold{
  color:#D8232A !important;
  font-size: 1.4rem !important;
  font:Poppins,medium
}
.registerLabelblack{
  color:#343433;
  font-size: 1.4rem !important;
}


.registerFormConCommsCon {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.registerFormConComms {
  font-size: 1.4rem !important;
  font-weight: 500;
}

/* Date picker */

.datePicker {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  width: 100% !important;
  font-size: 1.2rem !important;
}

.searchIcon {
  font-size: 16px;
}

.addressContainer {
  display: flex;
  align-items: center;
}