/*
 * Specials
 */

.specialsCon {
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .specialsCon {
    padding: 4em 7em;
  }
}

/*
 * Map
 */

.mapCon {
  width: 100%;
  margin-bottom: 2em;
  border: 0.2em solid var(--color-separator-light);;
}

.specialsImg {
  width: 100%;
  margin-bottom: 20px;
}

.iframeSpecial {
  width: 100%;
  height: 500px;
  border: none;
  margin-bottom: 20px;
}
