/*
  MENU
*/

.menuContainer {
  flex: 1;
  height: 8rem;
}

.menuContainer {
  background: inherit;
}

.extendMenuContainer {
  height: calc(100vh - 5em);
  background-color: var(--color-background);
  overflow-y: scroll;
}

@media (min-width: 62em) {
  .extendMenuContainerMobile {
    display: none;
  }
}

@media (min-width: 62em) {
  .menuContainer {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.menuVerticalSeparator {
  display: none;
  margin: auto 0.8em auto 0.8em;
  border-left: 1px solid var(--color-separator-light);
  height: 1.75em;
  width: 0.1rem;
}

@media (min-width: 62em) {
  .menuVerticalSeparator {
    display: block;
  }
}

.icon {
  font-size: 2.2rem;
  cursor: pointer;
}

icon:hover {
  color: var(--color-primary);
}

@media (min-width: 62em) {
  .icon {
    font-size: 1.8rem;
  }
}

/*
  G. FOX LOGO
*/

.logoContainer {
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 1.5em;
}

@media (min-width: 62em) {
  .logoContainer {
    padding-left: 0em;
  }
}

.gfoxLogo {
  width: 6.5em;
}

@media (min-width: 62em) {
  .gfoxLogo {
    width: 9em;
  }
}
/*
  ACCOUNT INFO
*/

.accountContainer {
  display: flex;
  align-items: center;
}

.avatarIconContainer {
  display: none;
  cursor: pointer;
}

@media (min-width: 62em) {
  .avatarIconContainer {
    display: block;
    margin-left: 0.8em;
  }
}

.lockContainer,
.cartContainer {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

@media (min-width: 62em) {
  .lockContainer,
  .cartContainer {
    margin-left: 0em;
    margin-right: 0em;
  }
}

.cartBadge {
  color: inherit !important;
  margin-top: 0.3em !important;
}

.lockLoginText {
  display: none;
}

@media (min-width: 62em) {
  .lockLoginText {
    display: block;
    font-size: 1.3rem !important;
    font-weight: 400;
    width: max-content;
    padding-left: 0.5em;
    padding-right: 0.8em;
    color: inherit !important;
  }
}

/*
  SEARCH
*/

.searchContainer {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

@media (min-width: 62em) {
  .searchContainer {
    flex: 1;
    margin-left: 0em;
    margin-right: 0em;
  }
}

.searchIcon {
  display: block;
}

.searchInput {
  display: none !important;
  color: #9a9a9a !important;
}

.searchInput > * {
  color: #9a9a9a !important;
}

@media (min-width: 62em) {
  .searchIcon {
    display: none !important;
  }

  .searchInput {
    display: inline-flex !important;
  }
}

/*
  MENU ITEMS
*/

.menuItemLink {
  display: none !important;
  color: inherit !important;
}

@media (min-width: 62em) {
  .menuItemLink {
    display: flex !important;
  }

  .menuItemLinkHoverBg:hover {
    background: var(--color-background);
    color: var(--color-primary) !important;
  }
}



.menuItemContainer {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 1.5em;
}

@media (min-width: 62em) {
  .menuItemContainer {
    display: none;
  }
}

.menuIcon {
  display: block;
  font-size: 2.6rem;
}

@media (min-width: 62em) {
  .menuIcon {
    display: none
  }
}

.menuItemText {
  margin-left: 1.25em;
  margin-right: 1.25em;
  font-size: 1.4rem;
  font-weight: 500;
  color: inherit !important;
  text-transform: uppercase;
}

.menuItemActiveText {
  font-weight: 700;
  text-transform: uppercase;
}

.menuItemProductMenu {
  display: none;
}

.menuItemLink:hover .menuItemProductMenu {
  color: var(--color-text-in-primary) !important;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: -1;
}

/*
  PRODUCT LIST MENU
*/

.productListContainer {
  height: 100%;
}

.productList {
  background-color: var(--color-background);
  height: 100%;
  padding: 4em 0em;
  display: flex;
  overflow-y: scroll;
}

.productListCategories {
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid #000;
  width: 25%;
  height: 100%;
}

.favoriteProduct {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
}
.favoriteProduct:hover {
  background: lightgray;
}


.productListText {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
  color: var(--color-text-in-primary);
  cursor: pointer;
}

.productListActive {
  background: var(--color-primary);
}

.productListText:hover {
  color: var(--color-primary);
}

.productListActive.productListText,
.productListActive.productListText:hover {
  color: var(--color-text-in-secondary);
}

.productListSubCategoryCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0em 2em;
  width: 75%;
}

.productListSubCategoryItem {
  text-align: left;
}

.productListSubCategory {
  text-align: left;
  width: 35%;
  display: inline-block;
}

.productListSubCategoryText {
  font-size: 1.4rem !important;
  padding: 0.5em;
  text-transform: capitalize;
}

.productListSubCategory:nth-child(2n) {
  width: 65%;
}

img.productListBanner {
  display: none;
  max-height: 10em;
  max-width: 100%;
  width: auto;
  height: auto;
}

@media (min-width: 62em) {
  img.productListBanner {
    display: inherit;
  }
}


/*
  Wishlist
*/

.icon{
  margin-left: 0em !important;
}

@media (min-width: 62em) {
  .icon {
    margin-left: 0.5em !important ;
  }
}
