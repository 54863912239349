/* ReviewCard.module.css */

.container {
  width: 90%;
  margin: 20px auto;
}

@media (min-width: 43em) {
  .container {
    width: 70%;
  }
}

@media (min-width: 62em) {
  .container {
    width: 50%;
  }
}

@media (min-width: 82em) {
  .container {
    width: 300px;
  }
}

.reviewCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.infoContainer {
  display: flex;
  flex-direction: column;
}

.date {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}

.comment {
  margin-top: 10px;
}

button.viewMoreBtn,
button.historyBtn,
button.reorderBtn {
  height: 2em;
  width: 6em;
  background-color: transparent; /* Remove background color */
  border: none; /* Remove border */
  color: inherit ; 
}

button.viewMoreBtn{
  top: 0.10em;
  bottom: 1em;
  right: 1em;

}

button.viewMoreBtn *,
button.historyBtn * ,
button.reorderBtn * { 
  font-size: 1.1rem !important;
}

button.reorderBtn {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.comment{
   font-size: 1.4rem !important;
   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
   
}

.date{
    font-size: 1.2rem !important;
}







