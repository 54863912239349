/*
 * Specials
 */

 .specialsCon {
    width: 100%;
    padding: 4em 1em;
  }
  
  @media (min-width: 62em) {
    .specialsCon {
      padding: 4em 7em;
    }
  }
  
  /*
   * Map
   */
  
  .mapCon {
    width: 100%;
    margin-bottom: 2em;
    border: 0.2em solid var(--color-separator-light);
  }
  
  