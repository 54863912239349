.cookieContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 62em) {
  .cookieContent {
    flex-direction: column;
    align-items: flex-end;
  }
}
  
  .cookieText {
    margin-right: 20px;
    font-size: 1.5rem !important;
  }
  
  .cookieBtn {
    color: 'red' !important;
    font-size: 1.7 !important;
  }

  .cookieDiv {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
