.specialsListItem {
  display: block;
  width: 100%;
  padding: 2em 1.5em;
  margin-bottom: 1em;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  height: 40em;
  cursor: pointer;
  position: relative;
}

@media (min-width: 43em) {
  .specialsListItem {
    width: calc(33.33% - 0.75em);

    height: auto;
    margin-right: 1em;
  }

  .specialsListItem:nth-child(3n) {
    margin-right: 0em;
  }
}

@media (min-width: 63em) {
  .specialsListItem {
    width: calc(25% - 0.75em);
  }

  .specialsListItem:nth-child(3n) {
    margin-right: 1em;
  }

  .specialsListItem:nth-child(4n) {
    margin-right: 0em;
  }
}


.specialsListImgCon {
}

.specialsListImgCon img {
  width: 100%;
  height: auto;
  max-height: 90%;
}

.specialsListContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: initial;
  bottom: 1.25em;
}

.specialsListSeprator {
  padding-bottom: 0.5em;
  background-color: var(--color-primary);
  border: none;
  width: 6em;
}

.specialsListTitle {
  padding-top: 0.3em;
  color: #343433 !important;
  font-size: 2.2rem !important;
  font-weight: 700;
  display: block !important;
}

@media (min-width: 43em) {
  .specialsListTitle {
    font-size: 1.6rem !important;
  }
}

.specialsListBranch {
  color: var(--color-primary) !important;
  font-size: 2.0rem !important;
  font-weight: 600;
}

@media (min-width: 43em) {
  .specialsListBranch {
    font-size: 1.4rem !important;
  }
}
