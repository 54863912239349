/* AverageRatingCard.module.css */

.noReviews {
  font-size: 16px; 
  text-align: center; 
}


.averageRatingCard {
  width: 90%;
  height: 200px;
}

@media (min-width: 43em) {
  .averageRatingCard {
    width: 70%;
  }
}

@media (min-width: 62em) {
  .averageRatingCard {
    width: 50%;
  }
}

@media (min-width: 82em) {
  .averageRatingCard {
    width: 300px;
  }
}

.ratingContainer {
  display: flex;
  align-items: center;
}

.rating {
  margin-right: 10px !important;
}

.ratingValue {
  font-size: 24px !important;  /* Adjust this value to make the rating value larger */
  font-weight: bold;
}


button.historyBtn,
button.reorderBtn {
  height: 2em;
  width: 6em;
}

button.historyBtn * ,
button.reorderBtn * {
  font-size: 1.1rem !important;
}

button.reorderBtn {
  position: absolute;
  bottom: 1em;
  right: 1em;
}
