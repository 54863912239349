.ordersCard{
  display: inline-block;
  width: calc(100%) !important;
  background-color: #FFFFFE;
  margin-bottom: 2em;
  border-radius: 10px;
}
button.loadMoreBtn{
  height: auto !important;
  padding: 0em !important;
  flex-direction: row !important;
  font-size: 1.4rem !important;
  font-weight: 800 ; 
  color: #9A9A9A !important;
}

.loadCon{
  position: relative !important;
  text-align: center !important;
}

/* 
  EMPTY LIST
 */

.emptyListCon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}


.tableCon {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}
.searchContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.searchInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.pageHeaderOptionSearch {
  border-radius: 20px;
  padding-left: 10px;
}

.searchDatePickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.searchContainer {
  margin-right: 1rem !important;
}

.datePickerContainer {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.datePicker {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

@media screen and (min-width: 62em) {
  .searchDatePickerContainer {
    flex-direction: row;
  }

  .searchInput,
  .datePicker {
    width: 300px !important;
  }
}

