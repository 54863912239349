.container {
  width: 100%;
  background-color: var(--color-background-footer);
  padding: 2em 1.5em;
}

.container, .container *{
  color: #fff
}

@media (min-width: 62em) {
  .container {
    padding: 2em 7em;
  }
}

/*
  Header
*/

.mainHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}


/*
  Items options
*/

.itemListContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .itemListContainer {
    flex-direction: row;
  }
}

.itemContainer {
  border-bottom: 1.6px solid #80807F;
  padding: 2em 0em ;
}

@media (min-width: 62em) {
  .itemContainer {
    flex: 1;
    display: flex;
    padding-right: 2em;
    padding-top: 0em;
    padding-bottom: 0em;
    border-bottom: none;
  }

  .itemContainer:nth-last-child(2)   {
    flex: 2;
    justify-content: flex-end;
    padding-right: 0em;
  }

  .itemContainer:nth-last-child(2) .itemContent {
    width: 50%;
    flex-direction: column;
  }

  .itemContainer:nth-last-child(2) .facebookDesktopIcon {
    display: block !important;
  }
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: left;
  /* padding: 1.5em 0em ; */

}

.itemHeaderText {
  color: var(--color-text-in-secondary) !important;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: left;
  text-transform: uppercase;
}

.expandIcon {
  display: block !important;
  font-size: 1.6rem;
  color: #80807F;
}

@media (min-width: 62em) {
  .expandIcon {
    display: none !important;
  }
}

/*
  FOOTER ITEMS
*/

.footerItemMobile {
  display: block;
}

@media (min-width: 62em) {
  .footerItemMobile {
    display: none;
  }
}

.footerItemLaptop {
  display: none;
}

@media (min-width: 62em) {
  .footerItemLaptop {
    display: block;
  }
}

.footerItemLink {
  margin-top: 0.8em;
  font-size: 1.4rem !important;
  color: #656565 !important;
  text-align: left;
  text-transform: uppercase !important;
}

/*
  FACEBOOK ICON
*/

.socialMediaCon{
  display: flex;
}
.facebookIcon {
  font-size: 2em;
}

.facebookIconMobile {
  display: block;
}

@media (min-width: 62em) {
  .facebookIconMobile {
    display: none !important;
  }
}

.facebookDesktopIcon {
  display: none !important;
  margin-top: 1em;
  padding-left: 0.5em;
}

/*
  SUBSCRIBE TO NEWSLETTER
*/

.newsletterContainer {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .newsletterContainer {
    position: absolute;
    right: -3em;
    bottom: 0;
  }
}


@media (min-width: 62em) {
  .newsletterContainer {
    position: absolute;
    right: -4em;
    bottom: 0;
  }
}

@media (min-width: 82em) {
  .newsletterContainer {
    right: 0em;
    bottom: 0;
  }
}

.newsletterText {
  color: #656565 !important;
  font-size: 1.2rem !important;
  text-align: left;
  margin-top: 0.5em;
}

.newsletterInput {
  margin-top: 1em;
}

/*
  COPY RIGHT INFO
*/

.copyrightContainer {
  margin-top: 3em;
  padding-top: 2em;
  text-align: left;
  border-top: 1px solid  #656565;
}

.copyrightText {
  font-size: 1.2rem !important;
  color: #656565 !important;
}
