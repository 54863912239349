/* 
  FILTER
*/

.con {
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .con {
    padding: 4em 7em;
  }
}

.conHeaderTitle {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

@media (min-width: 62em) {
  .conHeaderTitle {
    font-size: 2.8rem !important;
  }
}


/* 
  CERTIFICATES 
*/

.certContainer {
  display: flex;
  /* background-color: var(--color-background-secondary); */
  justify-content: center;
  width: 100%;
}

@media (min-width: 82em) {
  .certContainer {
    height: 40em;
    justify-content: flex-end;
  }
}

.certContent {
  padding: 2em 0em;
  width: 100%;
  /* background-color: var(--color-background-secondary); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 62em) {
  .certContent {
    padding: 2em 2em;
    width: 60%
  }
}

@media (min-width: 82em) {
  .certContent {
    width: 57%;
  }
}

.certImg {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .certImg {
    display: inline-block;
  }
}

.certDocumentLink {
  margin-bottom: 1em !important;
}

@media (min-width: 82em) {
  .certDocumentLink {
    margin-bottom: 0em !important;
  }  
}

.pdfIcon {
  font-size: 4.2rem !important;
}