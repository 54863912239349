/*
  CHECKOUT LAYOUT
*/
.checkoutConBg {
  background-color: var(--color-background-secondary);
}

.checkoutCon {
  padding: 1em 1em;
  margin-top: 2.5em;
}

@media (min-width: 62em) {
  .checkoutCon {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.checkoutPageTitle {
  font-weight: 600 !important;
  margin-bottom: 1em !important;
  font-size: 2.6rem !important;
}

.checkoutStepsCon {
  background-color: var(--color-background);
  padding: 1em 0em;
  width: 100%;
  height: 8em;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .checkoutStepsCon {
    height: auto;
    padding: 2em 1em;
  }
}

.checkoutHeaderCompCon {
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .checkoutStepsTitle {
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    text-align: center;
    display: flex;
    line-height: 1.5em;
  }
}

.checkoutStepsMobTitle {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-top: 2em !important;
  padding-left: 3em;
}

.checkoutMainCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 1em; */
}

@media (min-width: 43em) {
  .checkoutMainCon {
    /* margin-top: 1em; */
    flex-direction: row;
    width: 100%;
  }
}

.checkoutMainConFlexReverse {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 43em) {
  .checkoutMainConFlexReverse {
    flex-direction: row;
  }
}

.checkoutContent {
  flex: 1;
  margin-top: 1em;
  /* margin-bottom: 1em; */
  padding-right: 0em;
}

@media (min-width: 43em) {
  .checkoutContent {
    margin-top: 0em;
    padding-right: 1em;
  }
}

.checkoutContentHeader {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  padding-bottom: 1em !important;
  display: block;
  /* margin-top: 0.5em; */
}

@media (min-width: 43em) {
  .checkoutContentHeader {
    font-size: 1.8rem !important;
    margin-top: 0em;
  }
}

.checkoutSidePanel {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 43em) {
  .checkoutSidePanel {
    width: 40%;
    margin-top: 0em;
    margin-bottom: 0em;
    max-height: 100%
  }
}

@media (min-width: 62em) {
  .checkoutSidePanel {
    width: 30%;
  }
}

.checkoutPayCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.checkoutOptsCon{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

@media (min-width: 62em) {
  .checkoutOptsCon {
     display: flex;
     flex-direction: row;
     align-items: center !important;
     justify-content: flex-start !important;
     gap: 1em;
     flex-wrap: nowrap;
  }

  .checkoutPayCon{
    flex-direction: row;
    margin-bottom: 2.8em !important;
  }


}

.checkoutOptsBackArrow {
  font-size: 2rem !important;
}

button.checkoutOptsBtn {
  width: 80% !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.checkoutOptsTopBtn {
  width: 80% !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.saveAsStandingOrder{
  width: 80% !important;
  margin-bottom: 1em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  button.checkoutOptsBtn {
    width: inherit !important;
    margin-left: 2em;
  }

  button.checkoutOptsTopBtn {
    width: 49% !important;
  }

  button.checkoutOptsTopBtn:last-child{
    position: absolute;
  }

  button.checkoutOptsBtn:last-child {
    position: absolute;
    right: 0 !important;
  }

  button.saveAsStandingOrder{
    width: inherit !important;
    margin-left: 2em;
    margin-bottom: 2em !important;
    margin-top: 2em !important;
  }
}
