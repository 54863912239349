
.mobileMenuCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em 1em;
  height: 100%;
  background-color: var(--color-background);
  margin-bottom: 1em;
}

.menuItem {
  padding: 0;
  padding: 1.5em 0em;
} 

.menuItem,
.menuItem * {
  color: var(--color-text-in-primary) !important;
  text-align: left;
}

.menuItemBorder {
  border-bottom: 1px solid #343433;
}

.menuItemLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.menuItemText {
  padding: 0;
  font-size: 1.8rem;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.menuLoginBtnCon {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6em;
}

.menuLoginBtnCon > *{
  width: calc(50% - 0.5em);
}

.menuItemRightArrow {
  font-size: 2.0rem;
  color: var(--color-text-in-primary);
}

/* 
  PRODUCT LIST
*/

.productCategoryCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
}

.favoritesCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.productCategoryItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  text-transform: capitalize;
}

.productCategory {
  display: block;
  padding: 0.5em 1em;
  text-align: left;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.productCategory:first-child {
  padding-top: 1em;
}

.favoriteProduct {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
  flex: 1; 
}
.favoriteProduct:hover {
  background: lightgray;
}


/* 
  MOBILE CATEGORY OPTIONS
*/

.mobileCategoryHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: var(--color-primary);
  padding: 1em;
  margin-bottom: 2em;
}

.mobileCategoryCloseIcon {
  font-size: 2.4rem;
  color: var(--color-text-in-secondary) !important;
}

.mobileCategoryHeaderText {
  color: var(--color-text-in-secondary) !important;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  margin: 0em !important;
}

.mobileCategoryItemCon {
  text-align: left;
  padding-bottom: 1.5em;
}

.mobileCategoryItem {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}
