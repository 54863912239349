
/* 
  DETAILS
*/

.detailsCon {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .detailsCon {
    width: 70%;
  }
}

.cardSelectorCon {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.cardSelector {
  padding: 0em 0em 1em 0em !important;
  font-size: 1.4rem !important;
  width: 100%;
}

.cardIcon {
  font-size: 1.4rem;
}

.cardDigits {
  font-size: 1.4rem !important;
}

.cardDigits::before {
  content: '**** **** ****';
  margin-left: 1em
}

.cardCon {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .cardCon {
    width: 70%;
  }
}

.detailsText {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 1em !important;
}

