/* .tableCon {

} */
.OrderMonitorView {
  display: none !important;
}

@media (min-width: 43em) {
  .OrderMonitorView {
    display: inherit !important;
  }
}

.OrderMobileView {
  display: block !important;
}

@media (min-width: 43em) {
  .OrderMobileView {
    display: none !important;
  }
}
.tableRow {
  padding: 5em 0em;
}

.tableCon .ant-table .ant-table-tbody > tr > td {
  padding: 8em 0em !important;
}

.colRefNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: var(--color-primary) !important;
  white-space: nowrap;
}

.colDateNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}
.colDescriptionNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}

.colStatus {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

button.colViewBtn {
  padding: 0.5em !important;
  height: auto !important;
}

button.colViewBtn * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

button.colViewMonBtn {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  height: auto !important;
}

/* 
  Account Members
*/

.colMembersCon {
  display: flex;
  align-items: center;
}

.colMembersAvatar {
  display: none !important;
}

@media (min-width: 43em) {
  .colMembersAvatar {
    display: block !important;
  }
}

.colMembersNameCon {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}
