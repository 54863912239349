
/*
  CAROUSEL
*/

.carouselSubText {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .carouselSubText {
    font-size: 1.6rem !important;
  }
}

.playBtnCon {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.playBtnText {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.rectangle {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .rectangle {
        width: 24;
    }
}
.iconContainer {
    display: flex;
    padding-right: 10px !important;
}
@media (min-width:62em){
  .iconContainer{
    position: absolute;
    top: 3em;
  }
}
button.playBtnText * {
    font-weight: 500 !important;
}

/*
  WELCOME
*/

.welcomeContainer {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .welcomeContainer {
    height: 40em;
    justify-content: flex-end;
  }
    /* background-size: 50%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
  } */
}

.welcomeContent {
  padding: 6em 2em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .welcomeContent {
    /* padding: 6em 10em; */
    width: 60%
  }
}

@media (min-width: 82em) {
  .welcomeContent {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.welcomeImg {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .welcomeImg {
    display: inline-block;
  }
}

.welcomeTitle {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
}

@media (min-width: 82em) {
  .welcomeTitle {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.welcomeText {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .welcomeText {
    font-size: 1.6rem !important;
    text-align: left;
  }
}

.welcomeBtn {
  margin-top: 3.2em !important;
}

@media (min-width: 82em) {
  .welcomeBtn {
    /* margin-top: 2em !important; */
    align-self: flex-end;
  }
}

/*
  SHOP HEADER
*/

.shopHeaderContainer {
  margin-top: 3.8em;
  margin-bottom: 0em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 62em) {
  .shopHeaderContainer {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.shopHeader {
  font-size: 2.2rem !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .shopHeader {
    font-size: 2.6rem !important;
  }
}

.shopHeaderBtn {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 2px solid var(--color-secondary) !important;
}

.shopHeaderBtn *  {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}


/*
  POPULAR PRODUCTS
*/

.popularProdContainer {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 43em) {
  .popularProdContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .popularProdContainer {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.popularProdScroll {
  overflow-x: auto;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media (min-width: 62em) {
  .popularProdScroll {
    white-space: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .popularProdScroll {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.popularProdItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 26.5em;
  margin-top: 1.5em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .popularProdItem {
    width: calc(50% - 1em);
    display: flex;
    height: 30em;
    padding: 2em;
  }
}

@media (min-width: 62em) {
  .popularProdItem {
    height: 40em;
  }
}

.popularProdScroll .popularProdItem {
  display: inline-flex;
  width: 75vw !important;
  height: 75vw;
  margin-right: 1em;
}

.popularProdScroll .popularProdItem:last-child {
  margin-right: 0em;
}


@media (min-width: 43em) {
  .popularProdScroll .popularProdItem{
    width: calc(45% - 1em) !important;
    height: 26em;
  }
}

@media (min-width: 62em) {
  .popularProdScroll .popularProdItem{
    width: calc(50.33% - 1em) !important;
    /* margin-left: 0em; */
  }
}

.popularProdTitle {
  text-transform: uppercase;
  font-size: 4.2rem !important;
  font-weight: 800;
  text-align: left;
  line-height: 1em;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 43em) {
  .popularProdTitle {
    font-size: 5rem !important;
  }
}

.popularProdScroll .popularProdTitle  {
  font-size: 1.8rem !important;
}

.popularProdCategoryContainer {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
}

.popularProdCategory {
  font-size: 1.2rem !important;
  text-align: left;
  color: var(--color-text-in-secondary) !important
}

@media (min-width: 43em) {
  .popularProdCategory {
    font-size: 1.4rem !important;
  }
}

.popularProdBtnContainer {
  float: right;
}

.popularProdBtn,
.latestSpecialsBtn,
.otherOfferItemBtn,
.recentNewsBtn {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.popularProdBtn *,
.latestSpecialsBtn *,
.otherOfferItemBtn * ,
.recentNewsBtn *  {
  font-weight: 500 !important;
}


/*
  Latest Specials
*/

.latestSpecialsCon {
  position: relative;
  margin-top: 1em;
}

.latestSpecialsImg {
  width: 100%;
}

.latestSpecialsBtnCon {
  position: absolute;
  bottom: 1.5em;
  right:  1.5em;
}

@media (min-width: 62em) {
  .latestSpecialsBtnCon {
    bottom: 4em;
    left: 7em;
    right:  auto;
  }
}


/*
  OTHER OFFERS
*/

.otherOfferItemCon {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

@media (min-width: 43em) {
  .otherOfferItemCon {
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .otherOfferItemCon {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}

.otherOfferItemBg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
}

@media (min-width: 43em) {
  .otherOfferItemBg {
    width: 30%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .otherOfferItemBg {
    width: 50%;
  }
}

.otherOfferItemInfo {
  padding: 3em 2em;
  background-color: var(--color-background-secondary);
  text-align: left;
}

@media (min-width: 43em) {
  .otherOfferItemInfo {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;


    /* padding: 15em 5em 8em 5em; */
  }
}

.otherOfferItemHeader {
  font-size: 3.4rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  text-align: left;
}

@media (min-width: 62em) {
  .otherOfferItemHeader {
    font-size: 5rem !important;
  }
}

.otherOfferItemText {
  font-size: 1.4rem !important;
  text-align: left;
  margin-bottom: 2.5em !important;
}

@media (min-width: 43em) {
  .otherOfferItemText {
    /* width: 0%; */
    font-size: 1.8rem !important;
    padding-right: 1em;
  }
}

/*
.otherOfferItemBtn {
}
*/

/*
  RECENT NEWS
*/

.recentNewsCon {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 4em;
}


@media (min-width: 43em) {
  .recentNewsCon {
    padding-left: 1em;
    justify-content: space-between;
  }
}

@media (min-width: 62em) {
  .recentNewsCon {
    padding-left: 7em;
  }
}

.recentNewsItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 1em;
  min-width: 75%;
  height: 24em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .recentNewsItem {
    margin-left: 0em;
    min-width: auto;
    width: calc(65% - 1em); /* white space between*/
    height: 30em;
    padding: 2em;
  }

  .recentNewsItem:first-child {
    width: 35%;
  }
}

@media (min-width: 62em) {
  .recentNewsItem {
    height: 40em;
  }
}

.recentNewsDate {
  color: var(--color-text-in-secondary) !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  text-align: left !important;
}

@media (min-width: 62em) {
  .recentNewsDate {
    font-size: 1.4rem !important;
  }
}

.recentNewsTitle {
  color: var(--color-text-in-secondary) !important;
  font-size: 2.4rem !important;
  font-weight: 800 !important;
  text-align: left !important;
}

@media (min-width: 62em) {
  .recentNewsTitle {
    font-size: 3.8rem !important;
    margin-bottom: 2em;
    max-width: 20em;
  }
}

.recentNewsBtnCon {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  width: 100%;
}

.cursorPointer {
  cursor: pointer;
}
/* styles.module.css */

  .arrowLeft {
    font-size: 30px;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 40%;
    right: 3%;
    overflow: hidden;
    color: red;
  }
  .arrowLeft:hover{
    color: whitesmoke;
  }
  .arrowRight {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 40%;
    left: 3%;
    overflow: hidden;
    color:red;
  }
  .arrowRight:hover{
    color: whitesmoke;
  }





