@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-background: #FFFFFE;
  --color-background-secondary: #F7F7F7;
  --color-background-footer: #000000;
  /* --color-primary-lighter: #d7c499; */
  /* --color-primary-light: #f9f8f2; */
  --color-primary: #d8232a;
  /* --color-primary-dark: #12284d83; */
  --color-secondary: #000000;
  /* --color-secondary-dark: #12284d; */
  --color-title-in-primary: #000;
  --color-title-nav-transparent: #FFFFFE;
  --color-text-in-primary: #000;
  --color-text-in-secondary: #fff;
  --color-separator-light: #C7C7C7;
  /* --color-text-title: #32264d; */
  /* --color-text-complement: #9c98a6; */
  /* --color-text-base: #6a6180; */
  /* --color-text-brown: #59394a; */
  /* --color-line-in-white: #e6e6f0; */
  /* --color-input-background: #f8f8fc; */
  /* --color-button-text: #ffffff; */
  /* --color-box-base: #0a0101; */
  /* --color-box-footer: #fafafc; */

  --size-navbar-padding: 6em;

  font-size: 62.5%; /* Set rem to 10px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-background);
}

body {
  color: var(--color-text-in-primary);
}

/*
  NAVBAR
*/

/* TODO: sticky with color white */
.nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background-color: var(--color-background);
  border-bottom:  0.1rem solid var(--color-separator-light);
  color: var(--color-title-in-primary)
}

nav .navbar-icon {
  color: var(--color-title-in-primary)  !important
}
nav.transparent-navbar {
  background-color: rgba(0,0,0, 0.2) !important;
  border-bottom: none;
}

nav.transparent-navbar > * ,
nav.transparent-navbar .navbar-icon {
  color: var(--color-title-nav-transparent) !important;
}


@media (min-width: 62em) {
  nav.transparent-navbar {
     background-color: rgba(0,0,0, 0.2) !important;
  }
}

.navbar-padding {
  padding-top: 8rem;
}


/*
  TABS
*/

.ant-tabs-tab-active {
  border-bottom: 0.4em solid var(--color-primary) !important;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

/*
  TABLE
*/

.ant-table-thead > tr > th  {
  background-color: var(--color-background) !important;
}

/*
  MODALS
*/

.ant-modal-footer {
  border: none !important;
}

.ant-modal-title {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
}

.ant-modal-close-x {
  display: flex !important;
  justify-content: center;
  align-items: center;

  color: var(--color-title-in-primary) !important;
  font-size: 2.5rem !important;
}

.ant-modal-content {
  padding: 1em 1em;
}

nav.transparent-navbar{

}

/*
 * Google Recaptcha
 */

.grecaptcha-badge { visibility: hidden; }

