.labelText {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  text-align: center;
}

.labelText > * {
  text-align: inherit;
}

