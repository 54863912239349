/*
  CAROUSEL
*/

.carouselItem {
  height: 70vh;
}

.carouselSubText {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .carouselSubText {
    font-size: 1.6rem !important;
  }
}

.playBtnCon {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.playBtnText {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.rectangle {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .rectangle {
        width: 24;
    }
}
.iconContainer {
    display: flex;
    padding-right: 10px !important;
}
@media (min-width:62em){
  .iconContainer{
    position: absolute;
    top: 3em;
  }
}
button.playBtnText * {
    font-weight: 500 !important;
}

/*
  WELCOME
*/

.welcomeContainer {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .welcomeContainer {
    height: 40em;
    justify-content: flex-end;
  }
    /* background-size: 50%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
  } */
}

.welcomeContent {
  padding: 6em 2em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .welcomeContent {
    /* padding: 6em 10em; */
    width: 60%
  }
}

@media (min-width: 82em) {
  .welcomeContent {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.welcomeImg {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .welcomeImg {
    display: inline-block;
  }
}

.welcomeTitle {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
}

@media (min-width: 82em) {
  .welcomeTitle {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.welcomeText {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .welcomeText {
    font-size: 1.6rem !important;
    text-align: left;
  }
}

.welcomeBtn {
  margin-top: 3.2em !important;
}

@media (min-width: 82em) {
  .welcomeBtn {
    /* margin-top: 2em !important; */
    align-self: flex-end;
  }
}

/*
  TABS
*/

.tabCon {
  padding: 1em 2em;
}

.tabConBranches {
  padding-bottom: 0em !important;
}

@media (min-width: 62em) {
  .tabCon {
    padding: 1em 7em 4em 7em;
  }
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

/*
  CONTACT DETAILS
*/

.contactDetailsCon {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  margin-top: 1em;
}

@media (min-width: 43em) {
  .contactDetailsCon {
    width: 40em;
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

.contactDetailsCon hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.phoneBtn {
  flex: 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.phoneBtnIcon {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}

/*
  GET IN TOUCH
*/

.getInTouchCon {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .getInTouchCon {
    width: 70%;
  }
}

.getInTouchText {
  font-size: 1.6rem !important;
  font-weight: 600;
}

.getInTouchRadioGrp {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.getInTouchRadioGrp *  {
  font-size: 1.4rem !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .getInTouchCon {
    margin-bottom: 0em;
  }
}

.getInTouchFormCon {
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .getInTouchFormCon {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
    margin-right: 1em;
  }

  .getInTouchFormConInput {
    display: inline-block;
  }

  .getInTouchFormConInput:nth-child(2n) {
    margin-right: 0em;
  }

  .getInTouchFormConTextArea {
    width: 100%;
  }
}

.getInTouchLabel {
  font-size: 1.6rem !important;
}

/* .getInTouchFormItem {
} */

.getInTouchCommsCon {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.getInTouchComms {
  font-size: 1.4rem !important;
  font-weight: 500;
}

/*
  BRANCH TAB
*/



.branchTabCon {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  width: 100vw;
  margin-left: -2em;
  position: relative;
}

@media (min-width: 62em) {
  .branchTabCon {
    margin-left: -7em;
    margin-bottom: 0em;
  }
}

.branchMapCon {
  width: 100%;
  height: 100vh;
  background-color: #EDF3F4;
  position: relative;
}




.branchDetailsCon {
  height: 95vh;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 2em;
  box-shadow: 0px 6px 30px #00000019;
  background-color: var(--color-background);
  overflow-y: scroll;
  margin: auto;
}

@media (min-width: 62em) {
  .branchDetailsCon {
    position: absolute;
    top: 0;
    width: 25%;
    height: 90%;
    left: 7em;
    margin-top: 2%;
  }
}

.branchDetailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 0.5em);
  padding: 1em 1em 3em 0em;
}

.branchDetailsHeaderText {
  font-size: 2.0rem;
  font-weight: 600;
}

.branchDetailsHeaderCount,
.branchDetailsCount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.0rem;
  font-weight: 600;
  background-color: var(--color-primary);
  color: var(--color-text-in-secondary) !important;
  width: 3em;
  height: 3em;
}


.branchDetails {
  max-height: 100%;
  overflow-y: scroll;
}

.branchDetailsItem {
  border-top: 1px solid #C7C7C7;
  padding: 2em 0em;
}


.branchDetailsItemCon {
  display: flex;
}

.branchDetailsCount {
  width: 2.25em;
  height: 2.25em;
  font-size: 1.6rem;
}

.branchDetailsInfoCon {
  padding-left: 1em;
  display: flex;
  flex-direction: column;

}

.branchDetailsName {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
}

.branchDetailsInfo  {
  display: flex;
  padding-top: 0.5em;
}

.branchDetailsIcon {
  color: var(--color-primary);
  font-size: 1.5em !important;
}

.branchDetailsText,
.branchDetailsAddrText {
  padding-left: 0.5em;
  display: block;
  font-size: 1.4rem !important;
}

.branchDetailsAddrText {
  font-size: 1.1rem !important;
}

.locationButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}

.locationButton:focus {
  outline: none;
}

