:root {
  --color-background: #f5f5f5;
  --color-primary: #D8232A;
  --color-primary-hover: #fb040c;
  --color-secondary: #f0f0f0;
  --color-text: #333;
  --color-text-light: #666;
  --color-border: #e0e0e0;
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
  line-height: 1.6;
}

#reviews-root {
  width: 100% !important;
  min-height: 90vh !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.productOfferCon {
  padding: 6em 1em !important;
}

@media (min-width: 62em) {
  .productOfferCon {
    padding: 6em 7em !important;
  }
}

.header-section {
  margin-bottom: 3rem;
}

.main-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-primary);
  margin: 0;
  line-height: 1.2;
}

.subtitle {
  font-size: 1rem;
  color: var(--color-text);
  margin: 0.5rem 0 2rem 0;
}

.active-positions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.position-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--color-text);
  line-height: 1;
}

.position-label {
  font-size: 0.75rem;
  color: var(--color-text-light);
  letter-spacing: 0.05em;
}

.search-section {
  margin-top: 2rem;
}

.search-container {
  display: flex;
  gap: 1rem;
  max-width: 100%;
  margin-bottom: 2rem;
  background: transparent;
}

.search-input {
  flex: 2;
  background: transparent !important;

  &.ant-input-affix-wrapper {
    height: 48px !important;
    border-radius: 8px !important;
    padding: 0 11px;
    background: white;
    border: 1px solid var(--color-border);
    font-size: 1rem;
    display: flex;
    align-items: center;
    
    &:hover, &:focus {
      border-color: var(--color-primary);
    }
    
    .ant-input {
      height: 100%;
      background: transparent;
      font-size: 1rem;
      padding: 0;

      &::placeholder {
        font-size: 1rem;
        color: #666;
      }
    }

    .ant-input-prefix {
      margin-right: 10px;
      color: #666;
    }
  }
}

.province-select {
  flex: 1;
  
  .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
    padding: 0 11px !important;
    background: white !important;
    border: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    font-size: 1rem;
    
    &:hover {
      border-color: var(--color-primary);
    }
  }

  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 48px;
    font-size: 1rem;
    color: #666;
  }
}

.job-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.job-card {
  background-color: white;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: var(--color-primary);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.job-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.job-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--color-primary-hover);
  }
}

.job-summary {
  font-size: 14px;
  line-height: 1.6;
  color: var(--color-text-light);
  margin: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.more-link {
  color: var(--color-primary);
  text-decoration: none;
  margin-left: 5px;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.job-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid var(--color-border);
  margin-top: auto;
}

.job-location {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-text-light);
  font-size: 14px;

  .anticon {
    color: var(--color-primary);
  }
}

.job-date {
  color: var(--color-text-light);
  font-size: 14px;
}

.empty-review {
  margin: 50px;
  text-align: center;
  color: var(--color-text-light);
  font-size: 18px;
}

/* Responsive styles */
@media (max-width: 961px) {
  #reviews-root {
    padding-top: 50px;
  }

  .header-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .search-container {
    flex-direction: column;
    gap: 1rem;
    padding: 0 20px;
  }

  .search-input,
  .province-select {
    width: 100%;
    flex: none;
  }

  .search-input.ant-input-affix-wrapper {
    height: 48px !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
  }

  .province-select .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }

  .empty-review {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* Spinner styles */
.ant-spin {
  color: var(--color-primary);
}

.ant-spin-text {
  color: var(--color-text);
}

/* Additional utility classes */
.table-row-light {
  background-color: var(--color-secondary);
}

.table-row-dark {
  background-color: transparent;
}


@primary-color: #d8232a;@text-selection-bg: #ACCEF7;