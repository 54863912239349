
/*
 * Background image
 */

.carouselBackground {
  width: 100%;
  height: 100%;
  position: absolute;
}

.carouselBackground img {
  width: 100%;
  height: 100%;
}

.carouselItemContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width : 100%;
  height: 100vh;
}

.carouselItemContainerLeft {
    background-position: 60% 0%;
}

@media (min-width: 62em) {
  .carouselItemContainer {
    justify-content: center;
    align-items: flex-end;
    background-position: 0% 0;
    height: 90vh;
  }

  .carouselItemContainerLeft {
      align-items: flex-start;
      padding-left: 7em;
  }
}

.carouselTextContainer {
  padding: 1em;
  margin-bottom: 5em;
}

@media (min-width: 62em) {
  .carouselTextContainer {
    width: 55%;
    padding-right: 4em;
  }
}

.carouselSubHeaderText {
  color: var(--color-text-in-secondary) !important;
  text-align: left;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .carouselSubHeaderText {
    font-size: 2.5rem !important;
  }
}
.carouselHeaderText {
  color: var(--color-text-in-secondary) !important;
  text-align: left;
  font-size: 4.1rem !important;
  font-weight: 800 !important;
  margin-top: 0.2em !important;
  /* white-space: pre */
}

@media (min-width: 62em) {
  .carouselHeaderText {
    font-size: 7.0rem !important;
    margin-top: 0em !important;
    margin-bottom: 1rem !important;
    padding: 0em !important;
  }
}
