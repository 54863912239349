
.pdfHeader {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
}

@media (min-width: 82em) {
  .pdfHeader{
    font-size: 2.5rem !important;
    text-align: left;
    margin-bottom: 0em !important;
  }
}

.aboutUsText {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .aboutUsText {
    font-size: 1.6rem !important;
    text-align: left;
  }
}


/*
  PDF
*/

.pdfShowcaseCon {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .pdfShowcaseCon {
    padding: 6em 7em;
  }
}

.productOfferText {
  font-size: 1.6rem !important;
  text-align: left;
  width: 100%;
}

@media (min-width: 62em) {
  .productOfferText {
    width: 50%;
  }
}

.pdfCon {
  margin-top: 4em;
}

.pdItem {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  padding: 0em 1em;
  width: calc(50% - 0.5em);
  margin-bottom: 1em;
  min-height: 15em;
}

@media (min-width: 62em) {
  .pdItem {
    width: calc(20% - 2em);
    margin-right: 2.5em;
    margin-bottom: 2.5em;
    min-height: 20em;
  }

  .pdItem:nth-child(5n) {
    margin-right: 0em;
  }
}

.pdItem:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 62em) {
  .pdItem:nth-child(2n) {
    margin-left: 0em;
  }
}

.pdItemIcon {
  font-size: 5rem;
  padding: 1em 25% 0.5em 25%;
  border-bottom: 0.1em solid var(--color-primary);
}

@media (min-width: 62em) {
  .pdItemIcon {
    font-size: 7rem;
    border-bottom: 0.05em solid var(--color-primary);
  }
}

.pdItemName {
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: center !important;
  height: 4rem !important;
  padding: 1em 0em !important;
}

@media (min-width: 62em) {
  .pdItemName {
    padding: 2em 0em !important;
    font-size: 1.4rem !important;
  }
}



/*
  DELIVERIES
*/

.deliveriesCon {
  display: flex;
  background-color: var(--color-background-primary);
  justify-content: center;
  flex-direction: column;

}

@media (min-width: 82em) {
  .deliveriesCon {
    height: 45em;
    margin-bottom: 100px;
  }
}

/*
  POPULAR PRODUCTS
*/

.popularProdContainer {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 43em) {
  .popularProdContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .popularProdContainer {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.popularProdScroll {
  overflow-x: auto;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media (min-width: 62em) {
  .popularProdScroll {
    white-space: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .popularProdScroll {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.popularProdItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 26.5em;
  margin-top: 1.5em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .popularProdItem {
    width: calc(50% - 1em);
    display: flex;
    height: 30em;
    padding: 2em;
  }
}

@media (min-width: 62em) {
  .popularProdItem {
    height: 40em;
  }
}

.popularProdScroll .popularProdItem {
  display: inline-flex;
  width: 75vw !important;
  height: 75vw;
  margin-left: 1em;
}

@media (min-width: 43em) {
  .popularProdScroll .popularProdItem{
    width: calc(45% - 1em) !important;
    height: 26em;
  }
}

@media (min-width: 62em) {
  .popularProdScroll .popularProdItem{
    width: calc(33.33% - 1em) !important;
    margin-left: 0em;
  }
}

.popularProdTitle {
  text-transform: uppercase;
  font-size: 4.2rem !important;
  font-weight: 800;
  text-align: left;
  line-height: 1em;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 43em) {
  .popularProdTitle {
    font-size: 5rem !important;
  }
}

.popularProdScroll .popularProdTitle  {
  font-size: 1.8rem !important;
}

.popularProdCategoryContainer {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
}

.popularProdCategory {
  font-size: 1.2rem !important;
  text-align: left;
  color: var(--color-text-in-secondary) !important
}

@media (min-width: 43em) {
  .popularProdCategory {
    font-size: 1.4rem !important;
  }
}

.popularProdBtnContainer {
  float: right;
}

.popularProdBtn,
.latestSpecialsBtn,
.otherOfferItemBtn,
.recentNewsBtn {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.popularProdBtn *,
.latestSpecialsBtn *,
.otherOfferItemBtn * ,
.recentNewsBtn *  {
  font-weight: 500 !important;
}

/*
  SHOP HEADER
*/

.shopHeaderContainer {
  margin-top: 3.8em;
  margin-bottom: 0em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 62em) {
  .shopHeaderContainer {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.shopHeader {
  font-size: 2.2rem !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .shopHeader {
    font-size: 2.6rem !important;
  }
}

.shopHeaderBtn {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 2px solid var(--color-secondary) !important;
}

.shopHeaderBtn *  {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

/*
  CAROUSEL
*/

.carouselItem {
  height: 80vh;
}

.carouselSubText {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
  color: #FFFFFE;
}

@media (min-width: 62em) {
  .carouselSubText {
    font-size: 1.6rem !important;
    color: #FFFFFE;
  }
}


