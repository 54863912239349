/*
 * PRCICING
 */

.con {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.5em;
}

.pricingText {
  font-weight: 600;
  font-size: 1.4rem;
}

@media (min-width: 43em) {
  .pricingText {
    font-size: 1.6rem;
  }
}

.pricingTextOriginal {
  margin-left: 0.5rem;
  color: #808080;
  font-weight: 400;
  font-size: 1.2rem;
  text-decoration: line-through;
}

.pricingTextExcludingVAT {
  margin-left: 0.5rem;
  color: #808080;
  font-weight: 400;
  font-size: 1.2rem;
  margin-right: 0.5em !important;
}

@media (min-width: 43em) {
  .pricingText {
    font-size: 1.6rem;
  }
}

.onHoldLabel {
  position: absolute;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
}

.ecommerceText{
  font-size: 1.3rem !important;
  margin-left: 0.0em !important;
}
