.con {
  margin-top: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 1em 1em;
}

@media (min-width: 62em) {
  .con {
    padding: 1em 5.5em;
  }
}

.con  * {
  color: var(--color-text-in-primary) ;
}

.contentCon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em;
  background-color: var(--color-background);
  box-shadow: 0px 3px 20px #00000019;
  min-height: 24em;
  border-radius: 8px;
  /* min-height: 65vh; */
  height: 85vh;
}

@media (min-width: 62em) {
  .contentCon {
    height: auto;
    max-height: 45vh;
  }
}

.searchCloseBtn {
  padding: 0em 0em !important;
  height: auto !important;
  align-self: flex-end;
}

.searchCloseIcon {
  font-size: 2.2rem !important;
  color: var(--color-primary) !important;
}

.searchInputCon {
  display: block;
  margin: 0.5em 0em;
}

@media (min-width: 62em) {
  .searchInputCon {
    display: none;
  }
}

.searchSpinCon {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchItemsCon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.searchItem {
  display: flex;
  align-items: center;
  padding: 2em 0.5em;
  border-bottom: 0.1px solid #707070;
}

.searchItem:hover {
  background: var(--color-background-secondary);
  cursor: pointer;
}

.searchItemImg {
  height: 2.5em;
  width: 2.5em;
}

.searchItemImg img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.searchItemInfo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0em 0.5em !important;
}

.searchItemName {
  flex: 1 !important;
  font-size: 1.4rem !important;
  margin-bottom: 0.5em;
}

.searchItemPriceText {
  font-size: 1.4rem !important;
}

.searchItemPriceText2 {
  font-size: 1.2rem !important;
}

.searchItemInactive {
  font-size: 1.2rem !important;
  color: red !important;
}

.viewAllButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


.searchItemCategory{
  color: #ff0000 !important;
  font-size: 10px !important;
}

