.con {
  padding: 1em 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

/* .conFlexType {
} */

.headerText {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  display: block;
}


.conFlexType .headerCon {
  display: flex;
  align-items: flex-start;
}

.headlineImage {
  border-radius: 8px;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 8em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.conFlexType .headlineImage  {
  width: 50%;
  min-height: 100%;
  display: inline-block;
}

.metaData  {
  display: flex;
  flex-direction: column;
}

.conFlexType .metaData {
  flex-direction: column-reverse;
  display: inline-flex;
  width: 50%;
  padding-left: 1em;
}

.headlineDate,
.writtenBy {
  font-size: 0.8rem !important;
  color: #9A9A9A !important;
}

.writtenBy {
  padding-left: 1em;
}

.headerTitle {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  display: block;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}

.headlineContent {
  font-size: 1.4rem !important;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 4;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
}

.conFlexType .headlineContent {
  -webkit-line-clamp: 2;
}

.viewMoreBtnCon {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
}

button.viewMoreBtn {
  padding: 0.5em;
  height: auto;
}

button.viewMoreBtn * {
  font-weight: 500 !important;
}
