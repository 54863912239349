
.accountCon {
  width: 100%;
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .accountCon {
    padding: 2em 7em;
    flex-direction: row;
  }
}
.accountMenuCon {
  background-color: none;
  width: 100%;
  padding: 0.5em 0em;
  border-radius: 0.5em;
  max-height: 85vh;
}

@media (min-width: 62em) {
  .accountMenuCon {
    background-color: var(--color-background);
    padding: 0.5em;
    width: 20%;
  }
}

.accountMenuHeader {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  border-bottom: none;
  padding: 0em 0em;
}

@media (min-width: 62em) {
  .accountMenuHeader {
    font-size: 1.8rem !important;
    border-bottom: 1px solid #C7C7C7;
    padding: 1em 1em;
  }
}

.accountMenu {
  padding: 1em 0.5em !important;
  border-radius: 0.3em;
  background-color: var(--color-background);
  /* display: flex; */
  /* align-items: center; */
}

@media (min-width: 62em) {
  .accountMenu {
    padding: 0em 0em !important;
  }
}

button.accountMenuMoreBtn {
  padding: 0em;
}

.accountContentCon {
  width: 100%;
}

@media (min-width: 62em) {
  .accountContentCon {
    width: inherit;
    flex: 1;
    padding-left: 1em;
  }
}
