
/* 
  FILTER
*/

.filtersCon {
  width: 100%;
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .filtersCon {
    padding: 2em 7em;
  }
}

.filtersHeader {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .filtersHeader {
    font-size: 2.8rem !important;
  }
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.documentLink {
  margin-top: 1em !important;
}

.pdfIcon {
  font-size: 4.2rem !important;
}
