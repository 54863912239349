.loginFormSocialButtons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 5rem;
}

@media (min-width: 82em) {
  .loginFormSocialButtons {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
}


.loginOR {
  display: none;
  font-size: 1.4rem !important;
  justify-content: center;
}

@media (min-width: 82em) {
  .loginOR {
    font-size: 1.6rem !important;
    display: block;
    width: auto;
  }
}





.loginCon {
  height: auto;
  padding: 2em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .loginCon {
    padding: 0em 0em;
    display: flex;
    flex-direction: row-reverse;
    min-height: calc(100vh - 6em);
  }
}

.loginFormCon {
  display: block;
  width: 100%;
  padding: 0em;
}

@media (min-width: 62em) {
  .loginFormCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60% !important;
    padding-left: 8em;;
  }
}

.loginFormItemCon {
  margin-top: 1em;
}

@media (min-width: 62em) {
  .loginFormItemCon {
    width: 70%;
  }
}

.loginFormTitle {
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .loginFormTitle {
    font-size: 5.4rem !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.loginFormItemLabel {
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .loginFormItemLabel {
    font-size: 1.6rem !important;
  }
}

.loginFormRememberRadio {
  font-size: 1.1rem !important;
}

@media (min-width: 62em) {
  .loginFormRememberRadio {
    font-size: 1.4rem !important;
  }
}

.loginFormOptsCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .loginFormOptsCon {
    width: 70%;
  }
}

button.loginFormResetBtn,
button.loginFormSignUpBtn {
  height: auto !important;
  padding: 0em !important;
}

button.loginFormResetBtn *,
button.loginFormSignUpBtn * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: var(--color-primary);
}

button.loginFormSubmitBtn {
  margin-top: 2em;
  margin-bottom: 1em;
}

button.loginFormSignUpLargeBtn {
  margin-top: 2em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.loginFormSignUpTextCon {
  margin-bottom: 2em;
}

.loginFormSignUpText {
  font-size: 1.4rem !important;
}

/*
.loginFormSignUpBtn {
}
*/

.loginFormBanner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  margin-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 70%;
  height: 8.5em;
}

@media (min-width: 62em) {
  .loginFormBanner {
    flex: 1;
    align-items: flex-end;
    margin-top: 0em;
    height: inherit;
    padding: 0em 2em 4em 0em;
    justify-content: flex-end;
  }
}

.loginFormBannerText {
  font-size: 2.8rem !important;
  font-weight: 800;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 62em) {
  .loginFormBannerText {
    font-size: 8.6rem !important;
    line-height: 1em !important;
  }
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px !important;
}
