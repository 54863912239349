.tabCon {
  padding: 1em 1em;
  background-color: #f7f7f7;
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
  margin-bottom: 0em !important;
}

.registerHeader {
  align-self: center;
  color: #343433 !important;
  font: Poppins, bold;
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}
.registerRadioGrp {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.registerRadioGrp * {
  font-size: 1.4rem !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .registerHeader {
    font-size: 2.8rem !important;
  }
}

@media (min-width: 62em) {
  .tabCon {
    padding: 1em 7em 4em 7em;
    background-color: #f7f7f7;
  }
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.contactDetailsCon {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
}

@media (min-width: 43em) {
  .contactDetailsCon {
    width: 40em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.contactDetailsCon hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.phoneBtn {
  flex: 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.phoneBtnIcon {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}

/* 
  DETAILS
*/

.detailsCon {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .detailsCon {
    width: 70%;
  }
}

.detailsText {
  font-size: 1.6rem !important;
  font-weight: 600;
}

@media (min-width: 62em) {
  .registerFormCon {
    margin-bottom: 0em;
  }
}

.registerFormCon {
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .registerFormCon {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
    margin-right: 1em;
  }

  .registerFormConInput {
    display: inline-block;
  }

  .registerFormConInput:nth-child(2n) {
    margin-right: 0em;
  }

  .registerFormConTextArea {
    width: 100%;
  }
}

.registerLabel {
  font-size: 1.125rem !important;
}
.registerredSmall {
  color: #d8232a !important;
  font-size: 1.2rem !important;
}

.registerLabelred {
  color: #d8232a !important;
  font-size: 1.4rem !important;
}

.registerLabelredBold {
  color: #d8232a !important;
  font-size: 1.4rem !important;
  font: Poppins, medium;
}
.registerLabelblack {
  color: #343433;
  font-size: 1.4rem !important;
}

.registerFormConCommsCon {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.registerFormConComms {
  font-size: 1.4rem !important;
  font-weight: 500;
}

.select {
  margin-top: 1em !important;
  width: 100%;
  height: 3em !important;
  background: none !important;
  display: block !important;
}

.select * {
  text-align: left;
  height: 100% !important;
  background: none !important;
}

.select span {
  display: flex;
  align-items: center;
}

@media (min-width: 62em) {
  .select {
    width: 60% !important;
  }
}

.inputBackground{
  background: white !important;
}