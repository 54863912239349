/* .tableCon {

} */

.tableRow {
  padding: 5em 0em;
}

.tableCon .ant-table .ant-table-tbody > tr > td {
  padding: 8em 0em !important;
}

.colRefNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: var(--color-primary) !important;
}

.colDateNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}
.colDescriptionNo {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}

.colStatus {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

button.colViewBtn {
  padding: 0.5em !important;
  height: auto !important;
}

button.colViewBtn * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
