
.welcomeText {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  display: block;
  margin-top: 1em !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .welcomeText {
    font-size: 2.0rem !important;
  }
}

.welcomeSummaryText {
  font-size: 1.1rem !important;
  color: #9A9A9A !important;
}

@media (min-width: 62em) {
  .welcomeSummaryText {
    font-size: 1.6rem !important;
  }
}

img.bannerItem {
  margin-top: 0.5em !important;
  margin-bottom: 1em !important;
  width: 100%;
}

.dashboardContent {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 62em) {
  .dashboardContent > div,
  .dashboardContent > img {
    width: calc(50% - 1em) !important;
    height: 19em;
    overflow-y: scroll;
    margin: 0em 1em  1em 0em !important;
  }

  .dashboardContent > img {
    display: none !important;
  }

  .dashboardContent > :nth-child(2) {
    margin-right: 0em !important;
  }
}

@media (min-width: 82em) {
  .dashboardContent > div,
  .dashboardContent > img {
    width: calc(33.33% - 0.67em) !important;
    height: 19em;
    overflow-y: scroll;
    margin: 0em 1em  1em 0em !important;
  }

  .dashboardContent > img {
    display: block !important;
  }

  .dashboardContent > :nth-child(2) {
    margin-right: 1em !important;
  }

  .dashboardContent > :nth-child(3) {
    margin-right: 0em !important;
  }
} 

img.specialsImage {
  margin-bottom: 1em !important;
  width: 100%;
  border-radius: 8px;
}

@media (min-width: 62em) {
  img.specialsImage {
    margin-bottom: 0em !important;
  }
}

.dashboardContent2 {
  display: flex;
  flex-wrap: wrap;
}

.previousOrderCon {
  height: auto;
  width: 100%;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .previousOrderCon {
    width: calc(50% - 1em);
    height: 32em;
    margin-right: 1em; 
  }
}

@media (min-width: 82em) {
  .previousOrderCon {
    width: calc(30% - 0.75em);
  }

  .dashboardContent2 .previousOrderCon {
    height: 25em !important;
  }
}

.tabInfoCon {
  margin-top: 1em !important;
  padding: 0em 1em !important;
  border-radius: 8px;
  background-color: var(--color-background);
  width: 100%;
}


@media (min-width: 62em) {
  .tabInfoCon {
    margin-top: 0em !important;
    width: calc(50% - 1em);
    height: 32em !important;
    margin-right: 1em !important;   
    overflow: auto;
  }
}

@media (min-width: 82em) {
  .tabInfoCon {
    width: calc(40% - 0.75em);
    height: 25em !important;
  }
}

.dashboardContent2 .specialsImage {
  display: none;
}

@media (min-width: 62em) {
  .dashboardContent2 .specialsImage {
    display: block;
    width: calc(50% - 1em) !important;
    margin-left: 1em;
  }
}

@media (min-width: 82em) {
  .dashboardContent2 .specialsImage {
    display: none;
  }
}

.bankingInfoCon {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
  width: 100%;
}

@media (min-width: 62em) {
  .bankingInfoCon {
    margin-top: 0em !important;
    margin-bottom: 0em !important;
    width: calc(50% - 0.5em);
    height: 19em;
    margin-right: 0em; 
    overflow: auto;
  }
}


@media (min-width: 82em) {
  .bankingInfoCon {
    width: calc(30% - 0.75em);
  }
}

.bankingInfoHeader {
  display: block;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

.bankingInfoSubHeader {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.bankingInfoValue,
.bankingInfoValueExtra {
  font-size: 1.6rem !important;
  width: 50% !important;
}

.bankingInfoValueExtra {
  display: block ;
  width: 100% !important;
}
