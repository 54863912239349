
/* 
  TRENDING PRODUCTS
*/

.trendingProductsCon {
  margin-top: 1em;
  padding: 1em;
}

@media (min-width: 43em) {
  .trendingProductsCon {
    padding: 0em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .trendingProductsCon {
    display: flex;
    padding-left: 7em;
    padding-right: 7em;
    justify-content: space-between;
  }
}

.productCard {
  margin-bottom: 1em;
}

@media (min-width: 43em) {
  .productCard {
    width: calc(50% - 1em) !important;
    margin-left: 1em;
    display: inline-block;
  }
}

@media (min-width: 62em) {
  .productCard {
    width: calc(25% - 0.5em) !important;
    margin-left: 0.5em;
  }
}
/* 
  Trending Header
*/
.trendHeader {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
  margin-top: 1em;
}

@media (min-width: 82em) {
  .trendHeader{
    font-size: 2.5rem !important;
    text-align: left;
    margin-top: 2em !important;
  }
}

.trendingShowcaseCon {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .trendingShowcaseCon {
    padding: 6em 7em;
  }
}

.trendCon {
  margin-top: 1em;
}