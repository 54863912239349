
.prodInfo {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 62em) {
  .prodInfo {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.prodInfo {
  text-align: left;
}

.backNavCon {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2.5em;
  margin-bottom: 1em;
}

.backNavArrow {
  color: var(--color-primary);
  font-size: 2rem;
  cursor: pointer;
}

.backNavText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding-right: 0.5em;
  cursor: pointer;
}

@media (min-width: 62em) {
  .backNavText {
    font-size: 1.6rem !important;
  }
}

.backNavBorder {
  flex: 1;
  border-bottom: 1px solid #9A9A9A;
  height: 1em;
}

.infoCon {
  display: block;
}

@media (min-width: 62em) {
  .infoCon {
    display: flex;
    margin-top: 3em;
  }
}

.carouselCon {
  width: 100%;
}

@media (min-width: 62em) {
  .carouselCon {
    width: 40%;
  }
}

.carousel {
  width: 100%;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .carousel {
    margin-bottom: 1em;
  }
}

.carouselImgCon {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: calc(100vw - 2em);
  background: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .carouselImgCon {
    height: 35em;
  }
}

.carouselImg {
  width: auto !important;
  height: auto !important;
  max-width: 70%;
  max-height: 70%;
}

.carouselExtraImages {
  display: flex;
}

@media (min-width: 62em) {
  .carouselExtraImages {
    display: flex;
  }
}

.carouselImgOtherCon {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  background: var(--color-background-secondary);
  width: 7em;
  height: 7em;
  margin-right: 1em;
  cursor: pointer;
}

.carouselImgOtherConActive {
  border: 1px #000 solid;
}

.carouselImgOther {
  width: auto !important;
  height: auto !important;
  max-width: 70%;
  max-height: 70%;
}

/*
  .productInfoCon {
  }
*/

@media (min-width: 62em) {
  .productInfoCon {
    width: 60%;
    padding-left: 4em;
    padding-right: 4em;
  }
}

.productSku {
  color: #343433 !important;
  font-size: 1.1rem !important;
  font-weight: 600;
  text-align: left;
}

@media (min-width: 62em) {
  .productSku {
    font-size: 1.6rem !important;
  }
}

.productName {
  color: #343433 !important;
  font-size: 2.4rem !important;
  font-weight: 600;
  text-align: left;
  margin-top: 0em !important;
}

@media (min-width: 62em) {
  .productName {
    margin-top: 0.2em !important;
    font-size: 4.6rem !important;
    line-height: 1em !important;
  }
}

ul.productAttributesCon {
  padding: 1em 1em 0.5em 1em;
}

@media (min-width: 62em) {
  ul.productAttributesCon {
    padding: 1em 0em 0.5em 0em;
  }
}

ul.productAttributesCon li {
  color: #343433 !important;
  font-size: 1.4rem !important;
  padding-bottom: 0.5em;
}

@media (min-width: 62em) {
  ul.productAttributesCon > li {
    font-size: 1.4rem !important;
    min-width: 50%;
    display: inline-block;
  }

  ul.productAttributesCon > li::before{
    content: '\2022';
    margin-right: 1em;
    font-size: 1.4rem !important;
  }
}

button.productAttributesReadMore {
  padding: 0em;
  height: auto;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  button.productAttributesReadMore {
    display: none;
  }

  ul.productAttributesCon > li::before{
    content: '\2022';
    margin-right: 1em;
    font-size: 1.4rem !important;
  }
}

button.productAttributesReadMore * {
  color: var(--color-primary);
  font-size: 1.4rem !important;
  font-weight: 600;
}

.productSelectCon {
  flex-wrap: wrap;
  display: flex;
  margin-top: -1em;
  margin-bottom: 2em;
}

.productSelectItemCon {
  flex: 1;
  margin-top: 1em;
  margin-right: 1em;
  cursor: pointer;
}

@media (max-width: 43em) {
  .productSelectItemCon {
  }
}

.productSelectItemName {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #343433;
  font-size: 1.4rem !important;
  font-weight: 600;
}

.productSelectItemValueCon {
  display: flex;
  align-items: center;
}

.productSelectItemValue {
  color: #343433;
  font-size: 1.4rem !important;
}

.backOrderText {
  font-size: 1.4rem !important;
  color: red !important;
  margin-bottom: 2em;
}

.deliveryTimeText {
  font-size: 1.4rem !important;
  margin-bottom: 2em;
}

.quantityHeader {
  font-size: 1.4rem !important;
  font-weight: 600;
}

.priceCon {
  margin: 2em 0em;
}

.priceText {
  font-size: 1.8rem !important;
}

.priceText2 {
  font-size: 1.4rem !important;
}

.quantityCon {
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .quantityCon {
    display: flex;
    align-items: center;
    padding-top: 1em;
  }
}

.cartCountCon {
  display: flex;
  margin-top: 0.5em !important;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .cartCountCon {
    margin: 0em 4em 0em 0em !important;
  }
}

button.addToCartBtn {
  padding-left: 4em;
  padding-right: 4em;
}

.accountHolderNotice {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  border-top: 1px solid #343433;
}

.accountHolderNoticeHeader {
  color: var(--color-primary) !important;
  font-size: 1.4rem !important;
  font-weight: 600;
  margin-top: 1em;
}

.accountHolderNoticeText {
  font-size: 1.1rem !important;
  color: #343433;
  margin-top: 1em;
}

/*
  OTHER DETAILS
*/

.detailsCon {
  display: block;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .detailsCon {
    display: none;
  }
}

.detailsConTab {
  display: none !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.detailsGuideCon {
  display: block !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .detailsConTab {
    display: block !important;
  }
}

.detailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9A9A9A;
  margin-top: 1em;
  margin-bottom: 1em;
}

.detailsHeaderText {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.detailsHeaderTextActive {
  padding-bottom: 0em;
  border-bottom: 0.6rem solid var(--color-primary);
}

.detailsHeaderGuide {
  font-size: 1.4rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.detailsHeaderGuideActive {
  padding-bottom: 0em;
  border-bottom: 0.6rem solid var(--color-primary);
}

.detailsHeaderIcon {
  color: #343433;
  font-size: 2.0rem;
}


@media (min-width: 62em) {
  .detailsHeaderIcon {
    display: none !important;
  }
}

/* .detailsDescCon {

} */

.detailsDescTab{
  margin-left: 0.7em;
}

.detailsDescHtmlContent img {
  max-width: 100% !important;
}

.detailsDescText {
  font-size: 1.4rem !important;
  margin-top: 1em !important;
}

.detailsDescSubHeader {
  display: block;
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-top: 1em;
}

.detailsDescItem {
  font-size: 1.4rem !important;
  display: block;
}

.guidelinesTabCon {
  display: flex;
  flex-direction: column;
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
}

/*
  GUIDELINES TAB
*/

.guidelinesIconCon {
  display: flex;
  flex-wrap: wrap;
}

.pdfIcon {
  font-size: 4.4rem;
}

/*
  CHECKOUT MODAL
*/

.checkoutModal {
  width: 46em !important;
}

.checkoutModalBody {
  display: flex;
  justify-content: space-between;
}

.checkoutModalDetails {
  width: calc(50% - 2em);
  display: flex;
  flex-direction: column;
  justify-content:  space-between;
}

.checkoutModalDetailsHeader {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.5em;
}

.checkoutModalInfo {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.5em;
}

.checkoutModalInfo:nth-child(3) {
  margin-bottom: 0em;
}

.checkoutModalImgCon {
  width: calc(50% - 2em);
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 12em;
  background: var(--color-background-secondary);
}

/* Complimentary products */

.complimentaryHeader {
  font-size: 1.8rem !important;
  font-weight: 600;
}

.filterResultCardCon {
  text-align: left;
}

.filterResultCardCon {
  text-align: left;
}

.filterResultCard {
  display: inline-block;
  width: calc(50% - 0.5em) !important;
  margin-bottom: 1em;
}

@media (min-width: 82em) {
  .filterResultCard {
    width: calc(24.33% - 1em) !important;
  }
}

.filterResultCard:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 82em) {
  .filterResultCard:nth-child(2n) {
    margin-left: 0em;
  }

  .filterResultCard {
    margin-right: 1em;
  }
}

/* Rating  */

/* .cardsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: auto;
} */

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  height: 300px; /* Set a fixed height for vertical scrolling */
}

 @media (min-width: 62em) {
  .cardsContainer {
    display: flex;
    width: calc(50.33% - 0em) !important;
    align-items: center !important;
    overflow-x: auto;
    white-space: nowrap !important;
  }
}

button.historyBtn,
button.viewMoreBtn {
  height: 2em;
  width: 6em;
}

button.historyBtn * ,
button.viewMoreBtn * {
  font-size: 1.1rem !important;
}

button.viewMoreBtn {
  position: absolute;
  bottom: 0em;
  right: 1em;
}

/* Wishlist button  */
.wishlistButton {
  border: 1px solid #343433;
  padding: 1em !important;
  display: flex;
  margin-left: 0.5em !important;
  width: 4.6em !important;
}

.wishlistButtonIcon {
  font-size: 1.2rem !important;
}

button.wishlistButton{
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 1px solid #343433;
  /* background-color: transparent; */
}


/* LABEL */

.onHoldLabel {
  position: relative;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
  margin-bottom: 0.1em;
}

.labelContainer{
  position: relative;
}

.labelHold{
  position: absolute;
}

.filterBreadCrumb {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.filterBreadCrumbItem {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.filterBreadCrumbText {
  font-size: 1.4rem !important;
  white-space: nowrap;
}

.filterBreadCrumbText:hover {
  color: #d8232a;
}

.chevronIcon
{
  color: #d8232a;
}
