
.headerText {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.tableCon {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

.searchContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.searchInput {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.pageHeaderOptionSearch {
  border-radius: 20px;
  padding-left: 10px;
}

.searchDatePickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.searchContainer {
  margin-right: 1rem !important;
}

.datePickerContainer {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.datePicker {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}


@media screen and (min-width: 62em) {
  .searchDatePickerContainer {
    flex-direction: row;
  }

  .searchInput,
  .datePicker {
    width: 300px !important;
  }
}

