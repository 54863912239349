
.checkoutCon {
  margin-bottom: 1em;
}

.checkoutItemsCon {
  display: none;
}

@media (min-width: 62em) {
  .checkoutItemsCon {
    display: block;
  }
}

.checkoutTermsRadio {
  margin-bottom: 2.0em !important;
  width: 100%;
  font-size: 1.4rem !important;
}

.checkoutTermsBottomRadio {
  margin-top: 2.0em !important;
  margin-bottom: 2.0em !important;
  width: 100%;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .checkoutTermsBottomRadio {
    width: 80%;
    font-size: 1.4rem !important;
  }
}

@media (min-width: 62em) {
  .checkoutTermsRadio {
    width: 80%;
    font-size: 1.4rem !important;
  }
}

.checkoutNewsletterHeader {
  display: block !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.checkoutNewsletterText {
  display: block !important;
  font-size: 1.6rem !important;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .checkoutNewsletterHeader {
    font-size: 2.0rem !important;
  }
}

.checkoutNewsletterRadio {
  width: 100%;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .checkoutNewsletterRadio {
    width: 80%;
  }
}

.registerLabelredBold {
  color: #d8232a !important;
  font-size: 1.4rem !important;
  font: Poppins, medium;
}
