
/* 
  LINK
*/

.link {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color:  inherit
}

.link:hover, .link:hover *  {
  color: var(--color-primary) !important
}

/*
  PARAGRAPH
*/

.paragraph {
  font-size: 1.6rem;
}

/* 
  TEXT
*/

.text {
  font-size: 1.6rem;
}
