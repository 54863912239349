
.container {
  width: 100%;
  background: #FFFFFE 0% 0% no-repeat padding-box;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25em;
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFE 0%, #FFFFFE 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 43em) {
  .imgContainer {
    height: 40em;
  }
}

.imgContainer img {
  max-width: 90%;
  max-height: 90%
}

.separatorCon {
  border-top: 1px #343333 !important;
  width: calc(54% - 3em);
  height: 1px;
  border: 4px solid #D8232A;
  margin-right: 2px;
  text-align: center;
  
}

.infoContainer {
  padding: 1.5em;
  text-align: left;
}

.infoProdNameCon {
  height: calc(1.6rem * 4) !important;
}

.infoProdName {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500;
  display:inline-table;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .infoProdName {
    font-size: 1.2rem;
    font: Poppins;
    font-weight: bold;
  }
}

.infoOptionsCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoOptionsConViewBtn {
  margin-top: 0.5em;
}

@media (min-width: 43em) {
  .infoOptionsConViewBtn {
    justify-content: flex-end;
  }
}

.infoOptionsColorCon {
  display: flex;
  align-items: center;
}

.infoOptionsColorName {
  text-transform: capitalize;
  margin-left: 0.5em;
  font-weight: 1.6rem;
  font-weight: 300 !important;
  font-size: 1.5rem;
}

@media (min-width: 43em) {
  .infoOptionsColorName {
    font-size: 1.4rem;
  }
}

.infoOptionShopBtnIcon {
  color: var(--color-text-in-secondary) !important;
}

button.infoOptionShopBtn * {
  font-size: 2.2rem !important;
} 

button.infoOptionViewBtn {
  padding-left: 1em;
  padding-right: 1em;
  background: none !important;
}

@media (min-width: 43em) {
  .infoOptionViewBtnCon {
    margin-right: 1em !important;
  }

  button.infoOptionViewBtn {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* 
  COLOR INDICATOR
*/

.colorIndicatorCon {
  border: 1px solid #656565;
  border-radius: 50%;
  padding: 2px;
  width: 2em;
  height: 2em;
}

.colorIndicatorColor {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.detailsName {
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 400;
  padding-left: 15px;
  position:absolute;
}
@media (min-width: 43em) {
  .detailsName {
    font-size: 1.1rem;
    padding-left: 15px;
    font: Poppins;
  }
}

.positionName {
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 43em) {
  .positionName {
    font-size: 1.2rem;
    font: Poppins;
  }
}
@media (min-width: 43em) {
  .infoProdName {
    font-size: 1.6rem;
  }
}

.iconColor{
  color: #D8232A !important;
}