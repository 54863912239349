.OrderMonitorView {
  padding: 1em;
}

@media (min-width: 43em) {
  .OrderMonitorView {
    padding: 1.5em;
    display: inherit !important;
  }
}

.OrderMobileView {
  display: block !important;
}

@media (min-width: 43em) {
  .OrderMobileView{
    display: none !important;
  }
  
}


.orderIDCon {
}

.orderID {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  display:inline-table;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .orderID {
    font-size: 1.6rem !important;
  }
}


.total{
  font-size: 1.8rem !important;
  font-weight: 800 ;
  right: 0;
  margin-top: 1em;
}

@media (min-width: 43em) {
  .total{
    font-size: 2.4rem;
    font-weight: 800;
  }
}

.orderDate {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #9A9A9A !important;
  text-overflow: ellipsis;
  margin: 0em;
}

@media (min-width: 43em) {
  .orderDate {
    font-size: 1.6rem !important;
    font-weight: 500;
    font: Poppins;
    margin-top: 0.5em !important;
    margin-bottom: 0.75em !important;
  }
}

@media (min-width: 43em) {
  .orderID {
    font-size: 2.0rem;
  }
}

button.infoOptionViewBtn {
  padding-left: 1em;

}

.infoContainer {
  /* padding: 1.5em; */
  text-align: left;
  position: relative;
}

.infoProdNameCon {
  position: relative !important;
}

.infoProdNameCon .viewMoreBtn {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 43em) {
  .infoProdNameCon .viewMoreBtn {
    display: none;
  }
}


.totalCon{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.totalCon .viewMoreBtn {
  display: none;
}

@media (min-width: 43em) {
  .totalCon{
    justify-content: space-between;
  }

  .totalCon .viewMoreBtn {
    display: inline-flex;
  }
}

.dotIcon {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

.status{
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  flex-direction: row;
}

@media (min-width: 43em) {
  .status{
  font-size: 1.6rem;
  flex-direction: row;
  }
}
  
button.viewMoreBtn{
  height: auto !important;
  padding: 0em !important;
  flex-direction: row !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important; 
  color: #D8232A !important;
}

@media (min-width: 43em) {
  button.viewMoreBtn{
    color: #000000 !important;
    font-weight:600 !important;
  }
}

.btnCon {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5em;
  margin-top: 1em;
}

@media (min-width: 43em) {
  .btnCon {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    gap: 0.5em;
    margin-top: 0;
  }
}

.cancelOrder,
.trackOrder {
  width: 100%;
  padding-left: 1em !important;
  padding-right: 1em !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .cancelOrder,
  .trackOrder {
    width: auto;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }
  
  .cancelOrder {
    margin-right: 0.5em !important;
  }
  
  .trackOrder {
    margin-left: 0.5em !important;
  }
}