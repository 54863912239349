
/* 
  FILTER
*/

.filtersCon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .filtersCon {
    padding: 2em 7em;
  }
}

.filtersHeader {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .filtersHeader {
    font-size: 2.8rem !important;
  }
}

/* 
  SPECIALS LIST
*/

.specialsListCon {
  display: block;
  padding: 1em;
}

@media (min-width: 43em) {
  .specialsListCon {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 62em) {
  .specialsListCon {
    padding: 1em 7em;
  }
}
