/*
  TITLE
*/

.products {
  padding: 1em;
}

@media (min-width: 62em) {
  .products {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.titleCon {
  display: flex;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #9A9A9A;
}

.titleText {
  text-align: left;
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  border-bottom: 0.6rem solid var(--color-primary);
  margin: 0em !important
}

@media (min-width: 62em) {
  .titleText {
    font-size: 2.8rem !important;
    font-weight: 600 !important;
  }
}

/*
  FILTERS
*/

.filtersCon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 62em) {
  .filtersCon {
    justify-content: flex-end;
  }
}

.filtersBtn {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

@media (min-width: 62em) {
  .filtersBtn {
    display: none !important;
  }
}

.filtersOptCon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filterSearchIcon {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .filterSearchIcon {
    display: none !important;
  }
}

.filterSearchInputCon {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .filterSearchInputCon {
    display: flex;
    align-items: flex-end;
  }
}

.filterSearchInputConMobile {
  display: flex;
  width: 100%;
  margin-right: 0em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .filterSearchInputConMobile {
    display: block;
  }
}

.filterSearchInput {
  height: 100% !important;
}

.filterSelect {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .filterSelect {
    width: 16em !important
  }
}


/*
  FILTER
*/

.filterMenuResultsCon {
  display: block;
  margin-top: 2em !important;
}

@media (min-width: 62em) {
  .filterMenuResultsCon {
    display: flex;
  }
}
/*
  FILTER MENU
*/

.filterMenuCon {
  width: 100%;
  text-align: left;
  padding-right: 2em;
  border-right: 1px solid #9A9A9A;
  margin-right: 4em;
}

@media (min-width: 62em) {
  .filterMenuCon {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
}

.filterMenuHeader {
  display: block !important;
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0.7em;
  text-transform: uppercase;
}

.filterMenuHeaderCategories,
.filterMenuSubHeader,
.filterMenuCategories {
  display: none !important;
  text-transform: uppercase;
}

@media (min-width: 62em) {
  .filterMenuHeaderCategories,
  .filterMenuSubHeader,
  .filterMenuCategories {
    display: block !important;
  }
}

.filterMenuHeader.filterMenuHeaderFilterBy {
  margin-top: 1.5em;
  margin-bottom: 0em;
}

.filterMenuSubHeader,
.filterMenuFilterBySubHeader {
  font-size: 1.4rem !important;
  font-weight: 500;
  color: #656565 !important;
  margin-bottom: 0.7em;
}

.filterMenuFilterBySubHeader {
  margin-bottom: 0em;
  margin-top: 0.7em;
}

.filterMenuSubHeaderItemsActive {
  font-weight: 600;
  text-transform: capitalize;
}

.filterMenuSubHeaderItems {
  margin-bottom: 0.1em;
}

.filterMenuSubHeaderItems:hover {
  color: var(--color-primary);
}

.filterMenuByItemsCon {
  display: flex;
  flex-wrap: wrap;
}

.filterMenuSubHeaderItems,
.filterMenuFilterByItems {
  margin-left: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem !important;
  cursor: pointer;
  text-transform: capitalize;
}

.filterMenuFilterByItems {
  color: #9A9A9A !important;
  margin-left: 0em !important;
  font-weight: 400 !important;
  display: inline-block;
  width: 33.33%;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1em;
  font-size: 1.2rem !important;
}

.filterMenuFilterByItemsSelected {
  font-weight: 500 !important;
  color: var(--color-text-in-primary) !important;
}


.filterMenuSubHeaderCount {
  padding-left: 0.5em;
}

.filterMenuColorCon {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
}

.filterMenuColor {
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border:  none;
  margin-right: 0.5em;
  height: 1.5em;
  width: 1.5em;
}

.filterMenuColorActive {
  border: 1px solid #9A9A9A;
}

.filterMenuColorIcon {
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 50%;
  stroke: #9A9A9A80;
  stroke-width: 1px;
}

.filterMenuColorActive .filterMenuColorIcon{
  border: none;
}

/*
  RESULTS
*/

.filterResultCon {
  width: 100%;
}

.filterBreadCrumb {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.filterBreadCrumbItem {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.filterBreadCrumbText {
  font-size: 1.4rem !important;
  white-space: nowrap;
}

.filterResultsTextCon {
  flex: 1;
  margin-bottom: 1em !important;
  text-align: left;
}

.filterResultsText {
  font-size: 1.6rem !important;
}

.filterResultCardCon {
  text-align: left;
}

.filterResultCard {
  display: inline-block;
  width: calc(50% - 0.5em) !important;
  margin-bottom: 1em;
  position: relative;
}

@media (min-width: 82em) {
  .filterResultCard {
    width: calc(33.33% - 1em) !important;
  }
}

.filterResultCard:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 82em) {
  .filterResultCard:nth-child(2n) {
    margin-left: 0em;
  }

  .filterResultCard {
    margin-right: 1em;
  }
}

/* .filterResultCard:nth-child(3n) {
  margin-left: 1em;
} */

.filterResultPName {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

@media (min-width: 43em) {
  .filterResultPName {
    font-size: 1.6rem !important;
  }
}

.filterResultImg {
  height: 12em !important;
}

@media (min-width: 62em) {
  .filterResultImg {
    height: 19em !important;
  }
}

.emptyList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8em 0em;
}

/*
  MORE
*/

.moreTextCon {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

@media (min-width: 62em) {
  .moreTextCon {
    display: none;
  }
}

button.moreTextBtn {
  color: #656565;
  border: none !important;
  box-shadow: none;
}
