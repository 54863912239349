
.container {
  width: 100%;
  background: #F7F7F7 0% 0% no-repeat padding-box;
}

.imgContainerNoBG {
  background: none !important;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #FAFAFA 0%, #D8D8D8 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 43em) {
  .imgContainer {
    height: 15em !important;
  }
}

.imgContainer img {
  max-width: 85%;
  max-height: 85%
}

.separatorCon {
  border-top: 1px #343433 !important;
  width: calc(100% - 3em);
  height: 1px;
  border: 1px solid #343433;
  margin: auto;
  text-align: center;
}

.infoContainer {
  padding: 1.5em;
  text-align: left;
}

.infoProdNameCon {
  height: calc(1.8rem * 2) !important;
}

.infoProdName {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .infoProdName {
    font-size: 1.6rem;
  }
}


@media (min-width: 43em) {
  .infoPositionName {
    font-size: 1.6rem;
    font: Poppins
  }
}

.infoOptionsCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoOptionsConViewBtn {
  margin-top: 0.5em;
}

@media (min-width: 43em) {
  .infoOptionsConViewBtn {
    justify-content: flex-end;
  }
}

.infoOptionsColorCon {
  display: flex;
  align-items: center;
}

.infoOptionsColorName {
  text-transform: capitalize;
  margin-left: 0.5em;
  font-weight: 1.6rem;
  font-weight: 300 !important;
  font-size: 1.5rem;
}

@media (min-width: 43em) {
  .infoOptionsColorName {
    font-size: 1.4rem;
  }
}

.infoOptionShopBtnIcon {
  color: var(--color-text-in-secondary) !important;
}

button.infoOptionShopBtn * {
  font-size: 2.2rem !important;
}

button.infoOptionViewBtn {
  padding-left: 1em;
  padding-right: 1em;
  background: none !important;
}

@media (min-width: 43em) {
  .infoOptionViewBtnCon {
    margin-right: 1em !important;
  }

  button.infoOptionViewBtn {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/*
  COLOR INDICATOR
*/

.colorIndicatorCon {
  border: 1px solid #656565;
  border-radius: 50%;
  padding: 2px;
  width: 2em;
  height: 2em;
}

.colorIndicatorColor {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


/*
 * PRICING
 */
.pricingCon {
  margin: 2em 0em;
}

.pricingText {
  font-weight: 700;
}

/* LABEL */


.onHoldLabel {
  position: relative;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
  margin-bottom: 0.1em;
}

.labelHold{
  position: absolute;
}
.imageNavigation {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  pointer-events: none;
}

.prevArrow,
.nextArrow {
  font-size: 1.5rem;
  color: black;
  padding: 0.5em;
  cursor: pointer;
  pointer-events: all;
}

.prevArrow:hover,
.nextArrow:hover {
  color: red;
}
.imageWrapper {
  position: relative;
  width: 100%;
}