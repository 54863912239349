.con {
   display: flex;
   flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
  padding: 1em 1em;
  border-radius: 8px;
  position: relative;
  min-height: 27em;
}

.centerBtn {
    margin: auto !important;
    background-color: transparent !important;
      border: none !important;
}

.headerText {
  display: block;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

.chart {
  margin-top: 1em !important;
  width: 100% !important;
  height: auto !important;
}

.playBtnCon {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.playBtnText {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.rectangle {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.8rem !important;
}

@media (min-width: 62em) {
    .rectangle {
        width: 24;
    }
}
.iconContainer { 
    display: flex;
    padding-right: 10px !important; 
}

